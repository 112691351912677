.order-shipment{
  min-height: 100%;
  width: 100%;
  .batch-action{
    height: 116px;
    background-color: #ffffff;
    border-radius: 4px;
    .goods-title{
      margin-bottom: 20px;
    }
  }
  .batch-shipment-history{
    background-color: #ffffff;
    border-radius: 4px;
    padding:0px 20px 20px 20px;
    .filter-content{
      background-color: #f5f7fa;
      border-radius: 10px;
      margin:0px 0px 31px 0px;
      padding:20px;
      .action{
        margin-top:20px;
      }
    }
  }
}
.batch-modal-content{
  display:flex;
  .text-note{
    line-height:32px;
    margin-left:20px;
    a{
      text-decoration:none;
    }
  }
}