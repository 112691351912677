p {
	margin: 0;
	padding: 0;
}

.editLevel {
	width: 100%;
	padding: 0 10px;
	.flex{
		align-items: center;
	}
	.ml8{
		margin-left: 8px;
	}
	.content {
		padding: 20px;
		background-color: #ffffff;
		border-radius: 4px;
		.formTitle {
			font-weight: bolder;
		}
		.ant-divider-horizontal {
			margin-top: 12px;
		}
		.ant-input-affix-wrapper {
			width: unset;
		}
		.ant-form {
			position: relative;
		}
		.cardWrap {
			box-sizing: border-box;
			position: absolute;
			padding: 24px;
			top: 60px;
			right: 80px;
			width: 448px;
			height: 224px;
			border-radius: 24px;
			.cardTitle {
				font-size: 24px;
				font-weight: bolder;
				color: #ffffff;
			}
			.cardGrowth {
				margin-top: 16px;
				color: #ffffff;
				font-weight: bold;
				.growthNum {
					font-size: 24px;
				}
				.growth {
					margin-left: 8px;
					font-size: 16px;
				}
			}
			.nextLevel {
				color: #ffffff;
			}
			.tip {
				position: absolute;
				text-align: center;
				bottom: -24px;
				left: 50%;
				transform: translateX(-50%);
				color: #79797b;
			}
		}

		.ant-radio-group {
			display: flex;
			flex-direction: column;

			.ant-radio-wrapper {
				margin-bottom: 12px;
				display: flex;
				align-items: center;

				&:last-child {
					align-items: flex-start;
					margin-top: 12px;
				}
			}
		}

		.colorWrap {
			display: flex;
			align-items: center;

			.color-choose {
				margin-left: 12px;
				cursor: pointer;
				display: flex;
				width: 128px;
				height: 36px;
				background: rgba(255, 255, 255, 1);
				border: 1px solid rgba(220, 223, 230, 1);
				opacity: 1;
				border-radius: 4px;
				justify-content: center;
				align-items: center;

				.color {
					width: 20px;
					height: 20px;
					border: 1px solid rgba(220, 223, 230, 1);
					opacity: 1;
					border-radius: 2px;
				}

				.color-desc {
					width: 60px;
					height: 20px;
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 20px;
					color: rgba(85, 85, 85, 1);
					opacity: 1;
					margin-right: 9px;
				}

				.color-picker-icon {
					width: 12px;
					height: 8px;
					padding: 0px 10px;
					color: rgba(192, 196, 204, 1);
					opacity: 1;
				}
			}
		}

		.bacWrap {
			display: flex;
			flex-direction: column;

			.text {
				font-size: 14px;
				line-height: 14px;
				margin-bottom: 24px;
			}

			.tip {
				font-size: 12px;
				color: #7e7e80;
			}
		}

		.conditionWrap {
			.tip {
				font-size: 12px;
				color: #7e7e80;
			}
			.growthWrap {
				.grouth {
					display: flex;
					align-items: center;

					.text {
						flex-shrink: 0;
						margin-right: 12px;
					}
				}
			}
		}
		.btnWrap {
			margin-top: 48px;
			display: flex;
			justify-content: center;
			align-items: center;
			.cancel {
				margin-right: 12px;
			}
		}
		.selected-coupon {
			border-radius: 15px;
			border: 1px solid #d9d9d9;
			padding: 0px 10px;
			margin-right: 6px;
			position: relative;
			height: 30px;
			line-height: 30px;
			.img-close-icon {
				display: none;
				position: absolute;
				right: -5px;
				top: -5px;
			}
			&:hover {
				.img-close-icon {
					display: block;
				}
			}
		}
	}
}