.coupon-select-modal {
  .selected-coupon-list {
    padding: 10px 0 30px;
    .selected-coupon {
      border-radius: 15px;
      border: 1px solid #d9d9d9;
      padding: 4px 10px;
      margin-left: 16px;
      position: relative;
      .img-close-icon {
        position: absolute;
        display: none;
        right: -5px;
        top: -5px;
      }
      &:hover {
        .img-close-icon {
          display: block;
        }
      }
    }
  }
  width: 1070px !important;
  .form-input {
    width: 278px;
  }
  .ant-form-item-label {
    display: inline-block !important;
    text-align: left !important;
  }
  .ant-form-item-control-wrapper {
    display: inline-block;
  }
  .action {
    .text-note {
      margin-left: 12px;
      text-decoration: underline;
    }
  }
}
