.app-order{
  width:100%;
  height:100%;
  background-color: #e9eef3;
  padding: 0px 10px 10px 10px;
  .content{
    background-color: #ffffff;
    padding: 20px;
    border-radius: 4px;
    .app-version{
      display: -webkit-box;       /* OLD - iOS 6-, Safari 3.1-6 */
      display: -moz-box;          /* OLD - Firefox 19- (buggy but mostly works) */
      display: -ms-flexbox;       /* TWEENER - IE 10 */
      display: -webkit-flex;      /* NEW - Chrome */
      display: flex;              /* NEW, Spec - Opera 12.1, Firefox 20+ */
      width: 70%;
      margin-left:15%;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      -o-justify-content: space-between;
      justify-content: space-between;


    }

  }
}