.openingGuide {
    border-radius: 4px;
    background-color: #ffffff;

    .titleWrap {
        display: flex;
        align-items: center;
        padding-top: 14px;
        margin-left: 12px;

        .asideBar {
            width: 4px;
            height: 18px;
            background-color: #1790FF;
            border-radius: 2px;
        }

        .title {
            display: inline-block;
            height: 20px;
            margin-left: 8px;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            font-weight: 500;
        }
    }

    .content {
        position: relative;
        margin: 67px 97px;

        .connectLine {
            position: absolute;
            width: 1px;
            height: 181px;
            left: 11px;
            top: 30px;
            background-color: #E8EAEC;
        }

        .authorizeStepWrap {
            min-height: 222px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .stepWrap {
                .stepTitleWrap {
                    display: flex;
                    align-items: center;

                    .index {
                        width: 22px;
                        height: 22px;
                        border-radius: 50%;
                        background-color: #C8C9CC;
                        line-height: 22px;
                        text-align: center;
                        font-size: 16px;
                        color: #ffffff;
                        font-weight: 400;
                    }

                    .stepTitle {
                        margin-left: 12px;
                        font-size: 16px;
                        color: #333333;
                        font-weight: 500;
                    }
                }

                .statusWrap {
                    margin-top: 12px;
                    margin-left: 34px;

                    .stepSubtitle {
                        font-size: 14px;
                        color: #666666;
                        font-weight: 400;
                        line-height: 20px;
                    }

                    .status {
                        margin-top: 12px;
                        font-size: 14px;
                        line-height: 20px;
                        color: #E42317;
                    }

                    .detail {
                        margin-top: 6px;
                        font-size: 12px;
                        color: #333333;

                        .doSomthing {
                            margin-left: 6px;
                            cursor: pointer;
                            color: #1790FF;
                        }
                    }

                    .appletImg {
                        display: block;
                        margin-top: 6px;
                        width: 88px;
                        height: 88px;
                        border: 1px solid rgba(245, 245, 245, 1);
                    }
                }
            }

            .btnWrap {
                flex-shrink: 0;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .authorized {
                    display: flex;
                    align-items: center;

                    .icon-duigou {
                        color: #2BA246;
                    }

                    .authorizedText {
                        margin-left: 6px;
                        font-size: 14px;
                        color: #333333;
                        font-weight: 500;
                        line-height: 20px;
                    }
                }

                .hadApp {
                    margin-bottom: 12px;

                    .btnText {
                        color: #333333;
                        margin-right: 14px;
                    }
                }

                .noApp {
                    margin-bottom: 12px;

                    .btnText {
                        color: #333333;
                        margin-right: 14px;
                    }

                    .noAppTip {
                        cursor: pointer;
                        font-size: 14px;
                        color: #1790FF;
                        line-height: 20px;

                        .change {
                            margin-left: 8px;
                            color: #666666;
                        }
                    }
                }
            }
        }

        .payStepWrap {
            padding-bottom: 134px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .stepWrap {
                .stepTitleWrap {
                    display: flex;
                    align-items: center;

                    .index {
                        width: 22px;
                        height: 22px;
                        border-radius: 50%;
                        background-color: #C8C9CC;
                        line-height: 22px;
                        text-align: center;
                        font-size: 16px;
                        color: #ffffff;
                        font-weight: 400;
                    }

                    .stepTitle {
                        margin-left: 12px;
                        font-size: 16px;
                        color: #333333;
                        font-weight: 500;
                    }
                }

                .statusWrap {
                    margin-top: 12px;
                    margin-left: 34px;

                    .stepSubtitle {
                        font-size: 14px;
                        color: #666666;
                        font-weight: 400;
                        line-height: 20px;

                        .seeHelp {
                            color: #1790FF;
                            margin-left: 10px;
                            cursor: pointer;
                        }
                    }

                    .status {
                        margin-top: 12px;
                        font-size: 14px;
                        line-height: 20px;
                        color: #E42317;
                    }

                    .detail {
                        margin-top: 6px;
                        font-size: 12px;
                        color: #333333;

                        .doSomthing {
                            margin-left: 6px;
                            cursor: pointer;
                            color: #1790FF;
                        }
                    }

                    .appletImg {
                        display: block;
                        margin-top: 6px;
                        width: 88px;
                        height: 88px;
                        border: 1px solid rgba(245, 245, 245, 1);
                    }
                }
            }

            .btnWrap {
                flex-shrink: 0;

                .settled {
                    display: flex;
                    align-items: center;

                    .icon-duigou {
                        color: #2BA246;
                    }

                    .settledText {
                        margin-left: 6px;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        color: #333333;
                    }
                }
            }
        }
    }
}

.openingGuidemodalWrap {
    padding-top: 52px;
    padding-bottom: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .failTitle {
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 25px;
    }

    .seeHelp {
        cursor: pointer;
        margin-top: 13px;
        margin-bottom: 45px;
        font-size: 18px;
        font-weight: 400;
        color: #1790FF;
        line-height: 25px;
    }

    .icon-duigou {
        color: #2BA246;
        font-size: 35px;
    }

    .successTitle {
        margin-top: 21px;
        margin-bottom: 55px;
        font-size: 21px;
        font-weight: 400;
        color: #333333;

        &.personnal {
            margin-bottom: 25px;
        }
    }

    .tip {
        margin-bottom: 32px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
    }
}