.bonus-settle-content{
  padding:0px 20px 20px 20px;
  .fitlter-content{
    background-color: #f5f7fa;
    border-radius: 10px;
    padding:20px;
    .ant-select{
      width:200px;
    }
  }
    .text-note{
        margin-right:10px;
    }
  .action{
    margin-top:20px;
  }
  .table{
    margin-top:10px;
  }
  .action{
    .ant-btn{
      margin-right:10px;
    }
  }
}
