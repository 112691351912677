.goods-group-detail {
    width: 100%;
    min-height: 100%;
    background-color: #e9eef3;
    padding: 0px 10px 10px 10px;
    overflow: hidden;
    .content {
        background-color: #ffffff;
        border-radius: 4px;
    }
}
