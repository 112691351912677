.coupon {
    .ant-form-item-label, .ant-form-item-control-wrapper, .ant-form-item-control-wrapper {
        display: inline-block !important;
    }
    .coupon-desc {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(96, 98, 102, 1);
        line-height: 17px;
    }
    .text-note {
        margin-left: 6px;
    }
    .ant-form-extra {
        font-size: 12px;
    }
    .form {
        margin-left: 54px;
    }
    footer {
        margin-left: 154px;
    }
    .ant-form-item-label {
        width: 100px;
        text-align: left !important;
    }
    .ant-form-item-label {
        position: absolute;
        top: 0px;
    }
    .ant-form-item-control-wrapper {
        margin-left: 130px;
    }

    .ant-form-item-required {
        display: inline !important;
    }
    .form-long-content {
        width: 360px !important;
    }
    .form-short-content {
        width: 360px !important;
    }
    .text-danger {
        font-size: 12px;
    }
    .text-use {
        display: inline-block;
        width: 80px;
        text-align: left;
        margin-right: 10px;
    }
    .text-remind{
        margin-right:10px;
    }
    .ant-form-item label {
        position: relative;
        display: block;
        margin-bottom:10px;
    }
    .input-mid{
        width:280px;
    }
    .input-short{
        width:130px;
        margin-left:5px;
        margin-right:5px;
    }
    .table-box{
        margin-top:8px;
        padding:0px 20px 0px 20px;
        width:800px;
        border-radius:4px;
        border:1px solid rgba(226,229,234,1);
    }
    .table-goods-name{
        width:200px;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
    }
    .table-title{
        width:100px;
        font-size:14px;
        font-family:PingFangSC-Semibold,PingFang SC;
        font-weight:600;
        color:rgba(48,49,51,1);
        line-height:20px;
    }
    .iconfont{
        width:11px;
        height:11px;
        color:rgba(13,135,235,1);
        font-size:10px;
        margin-left:7px;
    }
    .remarks {
        resize: none;
        border-radius: 5px;
        width: 200px;
    }
}
