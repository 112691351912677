.refund-money-input {
	width: 320px;
}

.refund-form {
	margin-bottom: 20px;
}

.refound-modal {
	width: 1000px !important;

	.refundMoney {
		font-size: 16px;
		font-weight: bold;
		margin: 12px 12px 12px 0;
	}

	.ant-table-body {
		tr {
			&.notCut {
				td {
					color: #a0a0a0;
				}
			}
		}
	}

	footer {
		span {
			margin-right: 40px;
		}
	}
}