.applet-apply-manage {
  width: 100%;
  height: 100%;
  background-color: #e9eef3;
  padding: 0px 10px 10px 10px;
  .applet-apply-manage-content {
    background-color: #ffffff;
    padding: 20px;
    .filter-content {
      background-color: #f5f7fa;
      border-radius: 10px;
      padding: 10px 20px;
      margin: 0px 0px 30px 0px;
      .icon-content {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        background-color: #2589ff;
        display: flex;
        justify-content: center;
        align-items: center;
        .iconfont {
          font-size: 40px;
        }
      }
      .text-content {
        font-size: 14px;
        color: #606266;
        margin-left: 8px;
        margin-right: 28px;
        .title {
          font-size: 20px;
          color: #303133;
          font-weight: bold;
        }
      }
      .line {
        margin-top: 20px;
        .text-note {
          margin-left: 10px;
        }
      }
    }
  }
  .mr15 {
    margin-right: 15px;
  }
  .iconfont.icon-bianji {
    color: #0d87eb;
    margin-left: 4px;
  }
}
