$b: 0.046875;

@function base($px) {
    @return $px*$b+rem;
}

.imageUpload {
    min-height: 100%;
    background-color: #ececec;

    .logo {
        position: absolute;
        top: base(32);
        left: base(32);
        font-size: base(32);
        font-weight: bolder;
        color: #333333;
        text-shadow: 1px 1px 2px #3eaafe;
    }

    .favorites {
        position: absolute;
        top: base(24);
        right: base(32);
        font-size: base(16);
        font-weight: bolder;
        color: #888888;
    }

    .uploadContent {
        width: 50%;
        margin: 0 auto;
        padding-top: base(32);

        .btnWrap {
            width: 100%;
            height: 100%;
            margin-right: base(32);

            &>span {
                display: inline-block;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .ant-upload-drag {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: base(520);
                    height: base(280);
                    border-radius: base(12);
                    background-color: #ffffff;
                    cursor: pointer;

                    .anticon {
                        font-size: base(64);
                    }

                    .ant-upload-hint {
                        font-size: base(16);
                    }
                }

                .ant-upload-list {
                    position: relative;
                    width: base(320);
                    height: base(520);
                    overflow: scroll;
                    padding-bottom: base(32);
                    border-radius: base(12);
                    border: 1px dashed #d9d9d9;
                    background-color: #fafafa;

                    &:hover {
                        border-color: #3eaafe;
                    }

                    &::before {
                        position: absolute;
                        content: 'List of files just uploaded';
                        width: 100%;
                        height: base(32);
                        font-size: base(16);
                        line-height: base(32);
                        text-align: center;
                    }

                    .ant-upload-list-item {
                        &:first-child {
                            margin-top: base(32);
                        }
                    }
                }
            }
        }
    }

    .historyContent {
        margin-top: base(36);
        padding: 0 base(48) base(64) base(48);

        .historyTitle {
            font-size: base(32);
            font-weight: bolder;
            color: #333333;

            .tips {
                font-size: base(16);
                color: rgba(0, 0, 0, .4);
            }
        }

        .content {
            margin-top: base(32);
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .item {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: base(324);
                height: base(240);
                margin-right: base(16);
                margin-bottom: base(16);
                background-color: #ffffff;
                cursor: pointer;

                .itemImg {
                    max-width: base(324);
                    max-height: base(240);
                }

                .modal {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba(0, 0, 0, 0.6);
                }
            }
        }
    }

    .connect {
        font-size: base(16);
        text-align: center;
    }
}