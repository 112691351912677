.page,.integral{
    width:100%;
    min-height:100%;
    background-color: #e9eef3;
    padding: 0px 10px 10px 10px;
    .content{
        background-color: #fff;
        border-radius: 4px;
        padding:20px;
        .switch-desc{
            width:112px;
            height:20px;
            font-size:14px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:400;
            color:rgba(48,49,51,1);
            line-height:20px;
            margin-left:15px;
        }
        .input-short{
            width:280px;
            margin-left:8px;
        }
        .input-desc{
            display: inline-block;
            width:80px;
            text-align: right;
        }
        .integral-dashboard{
            display: flex;
            justify-content: center;
            text-align: center;
            justify-content: space-around;
            min-height:140px;
            background:rgba(255,255,255,1);
            border:1px solid rgba(228,231,237,1);
            align-items: center;
            margin-top:10px;
            .integral-title{
                height:22px;
                font-size:16px;
                font-family:PingFangSC-Regular,PingFang SC;
                font-weight:400;
                color:rgba(96,98,102,1);
                line-height:22px;
            }
            .integral-data{
                height:42px;
                font-size:30px;
                font-family:PingFangSC-Semibold,PingFang SC;
                font-weight:600;
                color:rgba(96,98,102,1);
                line-height:42px;
            }
        }
        .integral-desc-title{
            height:20px;
            font-size:14px;
            font-family:PingFangSC-Semibold,PingFang SC;
            font-weight:600;
            color:rgba(48,49,51,1);
            line-height:20px;
        }
        .integral-desc{
            margin-top:8px;
            height:20px;
            font-size:14px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:400;
            color:rgba(96,98,102,1);
        }
        .integral-rule{
            padding:24px 20px;
            min-height:148px;
            background:rgba(245,247,250,1);
            border:1px solid rgba(228,231,237,1);
        }
        .goto-integral-market{
            margin-bottom:20px;
            height:96px;
            background:rgba(245,247,250,1);
            border:1px solid rgba(228,231,237,1);
            margin-top:18px;
            padding:24px 20px;
            .text-note{
                text-decoration: #2589ff;
                text-decoration-line: underline;
                margin-left:12px;
            }
        }
        .integral-goods{
            display:flex;
            max-height:454px;
            flex-wrap: wrap;
            .img-content {
                width: 102px;
                height: 102px;
                border-radius: 4px;
                cursor: pointer;
                border: 1px solid #d9d9d9;
                text-align: center;
                margin-right:12px;
                position:relative;
                margin-bottom: 10px;
                .icon-del{
                    color:#303133;
                    position:absolute;
                    top:-8px;
                    right:-8px;
                    z-index:11111;
                    font-size:16px;
                    display:none;

                }
            }
            .img-content:hover{
                .icon-del{
                    display:inline-block;
                }

            }
            .integral-goods-show{
                display:flex;
                flex-wrap: wrap;
            }
            img{
                width:90px;
                height:90px;
                margin-top:5px;
            }
        }
        .integral-goods-add{
            width:100px;
            height:100px;
            background:rgba(255,255,255,1);
            border-radius:4px;
            border:1px dashed rgba(220,223,230,1);
            font-size:14px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:400;
            color:rgba(13,135,235,1);
            line-height:100px;
            text-align: center;
            cursor: pointer;
            margin-right:12px;
        }
        footer{
            text-align: center;
        }
    }
}
.integral-tab{
    .page {
        background: #ffffff;
        .content{
            padding:0px;
        }
    }
}
.integral{
    .ant-form-item{
        display:flex;
    }
    .text-danger{
        margin-left:100px;
    }
    .ant-col{
        max-width: 80%;
    }
}
