.after-sale-manage{
  width:100%;
  height:100%;
  padding:0px 10px 10px 10px;
  .content{
    background-color: #ffffff;
    border-radius: 4px;
  }

}
