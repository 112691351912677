.searchAndTable {
    .ant-form {
        display: flex;

        .ant-row {
            margin-right: 24px;
        }
    }

    .clear {
        cursor: pointer;
        margin-left: 24px;
        color: #1890ff;

        &:hover {
            opacity: 0.8;
        }
    }

    .ant-table-wrapper {
        margin-top: 36px;
    }
}