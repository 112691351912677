p {
    margin: 0;
    padding: 0;
}

.basicInfo {
    width: 100%;
    padding: 0 10px;

    .content {
        padding: 20px;
        border-radius: 4px;
        background-color: #ffffff;

        .titleWrap {
            display: flex;
            align-items: center;
            padding-top: 14px;

            .asideBar {
                width: 4px;
                height: 18px;
                background-color: #1790FF;
                border-radius: 2px;
            }

            .title {
                display: inline-block;
                height: 20px;
                margin-left: 8px;
                font-size: 14px;
                line-height: 20px;
                color: #333333;
                font-weight: 500;
            }
        }

        .main {
            margin: 32px 0 0 16px;

            .infoItem {
                display: flex;
                align-items: center;
                margin-bottom: 16px;

                &.top {
                    align-items: flex-start;
                }

                .name {
                    width: 120px;
                    margin-right: 32px;
                    text-align: right;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                }

                .appletQrcode {
                    width: 120px;
                    background-color: #eeeeee;
                }
            }
        }
    }
}