.order-shipment{
  padding: 0px 10px 10px 10px;
  .ant-tabs{
    background: white;
  }
}

.self-order-shipment{
  background-color: #ffffff;
  border-radius: 4px;
  padding:20px;
  .filter-content{
    background-color: #f5f7fa;
    border-radius: 10px;
    padding:20px;
    margin-bottom: 20px;
    .action{
      margin-top:20px;
      .ant-btn{
        margin-right: 10px;
      }
    }
  }
}