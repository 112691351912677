.promote-chanel{
    width: 100%;
    min-height: 100%;
    padding:0px 10px 10px 10px;
    background-color: #e9eef3;
    overflow: hidden;
    .content{
        background-color: #ffffff;
        border-radius: 4px;
        padding:20px;
        .filter-content{
            margin-top:20px;
            .text-note{
                margin-left:20px;
            }
        }
        .iconfont{
            color: #2589ff;
            margin-left:10px;
        }

    }
}
