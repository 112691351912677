.user-manage {
  width: 100%;
  height: 100%;
  background-color: #e9eef3;
  padding: 0px 10px 10px 10px;
  .user-manage-content {
    background-color: #ffffff;
    padding: 20px;
    .filter-content {
      background-color: #f5f7fa;
      border-radius: 10px;
      padding: 20px;
      margin: 0px 0px 30px 0px;
    }
    .action {
      margin-bottom: 10px;
    }
    .text {
      font-size: 12px;
      color: #909399;
      margin-left: 20px;
    }
    .ant-btn {
      font-size: 14px;
    }
    .line {
      margin-top: 20px;
      .text-note {
        margin-left: 10px;
      }
    }
  }
  .red {
    color: red;
  }
}
