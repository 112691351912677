.goodsPosterWrap {
    background-color: #f4f4f4;
    ;
    padding: 28px;

    p {
        margin-bottom: 0;
    }

    .poster {
        border-radius: 10px 10px 0 0;
    }

    .goodsInfos {
        height: 180px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: 28px;
        background-color: #ffffff;
        border-radius: 0 0 10px 10px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

        .infosLeft {
            width: 174px;
            margin-right: 9px;

            .title {
                height: 40px;
                overflow: hidden;
                line-height: 20px;
                font-size: 14px;
                color: #555555;
            }

            .price {
                margin-top: 20px;
                color: #811616;
                font-size: 18px;

                .bold {
                    margin-right: 2px;
                    font-weight: bold;
                }
            }

            .originPrice {
                margin-top: 4px;
                font-size: 12px;
                color: #999999;
                text-decoration: line-through;

                .bold {
                    font-weight: bold;
                    text-decoration: none;
                }
            }
        }

        .infosRight {
            .tips {
                margin-top: 8px;
                font-size: 12px;
                color: #999999;
            }
        }
    }
}