.client-manage,
.client {
	width: 100%;
	height: 100%;
	background-color: #e9eef3;
	padding: 0px 10px 10px 10px;

	.content {
		padding: 20px;
		border-radius: 4px;
		background: #ffffff;

		.ant-form-item {
			margin-right: 20px !important;
		}

		.tag-info {
			margin-bottom: 10px;
		}

		.user-info {
			display: flex;
			align-items: center;

			.user-info-detail {
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: rgba(96, 98, 102, 1);
				margin-left: 10px;
			}
		}

		.form-input {
			width: 200px !important;
		}

		.form-divider {
			display: inline-block;
			width: 20px;
			text-align: center;
		}

		.action {
			margin-top: 20px;
			cursor: pointer;

			.text-note {
				margin-left: 10px;
			}
		}
	}

	.client-info {
		display: flex;

		.base-info {
			.user-avatar {
				margin-top: 20px;
				display: flex;
				align-items: center;

				.user-name {
					display: flex;
					flex-direction: column;
					margin-left: 20px;

					.name {
						margin-bottom: 6px;
					}
				}
			}

			.client-info-detail {
				margin-top: 20px;
				border: 1px solid rgba(220, 223, 230, 1);
				border-radius: 4px;
				padding: 0 20px;
			}

			.client-info-address {
				justify-content: space-between;
				flex-wrap: wrap;
			}
		}

		.info-block {
			.label {
				padding-bottom: 20px;
				display: inline-block;
				width: 135px;
				min-width: 135px;
				text-align: right;
				border-right: 1px solid rgba(220, 223, 230, 1);
			}

			.value {
				padding-left: 10px;
			}

			&:first-child .label {
				padding-top: 20px;
			}
		}

		.right-content {
			flex: 1;
			margin-left: 10px;

			.ant-checkbox-wrapper {
				margin-left: 0;
				margin-right: 8px;
			}

			.asset-info {
				margin-bottom: 40px;

				.asset-detail {
					border: 1px solid rgba(220, 223, 230, 1);
					border-radius: 4px;
					margin-top: 20px;
					height: 150px;
					display: flex;
					justify-content: space-around;
					align-items: center;

					&.userInfo {
						box-sizing: border-box;
						padding: 12px;
						flex-direction: column;
						justify-content: flex-start;
						align-items: flex-start;

						.asset-item {
							.item-value {
								color: #333333;
								font-size: 16px;
								font-weight: normal;
								margin-bottom: 4px;
							}
						}
					}

					.asset-item {
						display: flex;
						flex-direction: column;
						font-size: 12px;
						color: rgba(51, 51, 51, 1);
						text-align: center;

						.item-value {
							font-size: 18px;
							font-weight: 500;
							color: rgba(13, 135, 235, 1);
						}
					}
				}
			}
		}
	}

	.vip-info {
		padding: 20px;
		margin-top: 24px;
		border-radius: 4px;
		background-color: #ffffff;

		.rights-info {
			// border: 1px solid rgba(220, 223, 230, 1);
			border-radius: 4px;
			margin-top: 20px;
			padding-bottom: 20px;

			.info-block {
				display: flex;
				align-items: center;
				margin-top: 10px;

				.label {
					border-right: none;
					width: 100px;
				}

				&:last-child .label {
					padding-bottom: 0;
				}
			}

			.rights-item {
				display: flex;
				align-items: center;
				font-size: 14px;
				color: rgba(96, 98, 102, 1);
				margin-bottom: 4px;

				.index {
					margin-right: 4px;
					color: #fff;
					font-size: 12px;
					border-radius: 100%;
					background-color: #bee1fe;
					width: 16px;
					height: 16px;
					line-height: 16px;
					text-align: center;
				}
			}
		}
	}

	.assetInformation {
		margin-top: 24px;
		padding: 20px;
		border-radius: 4px;
		background-color: #ffffff;

		.asset-detail {
			border: 1px solid rgba(220, 223, 230, 1);
			border-radius: 4px;
			margin-top: 20px;
			height: 150px;
			display: flex;
			justify-content: space-around;
			align-items: center;

			&.userInfo {
				box-sizing: border-box;
				padding: 12px;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;

				.asset-item {
					.item-value {
						color: #333333;
						font-size: 16px;
						font-weight: normal;
					}
				}
			}

			.asset-item {
				display: flex;
				flex-direction: column;
				font-size: 12px;
				color: rgba(51, 51, 51, 1);
				text-align: center;

				.item-value {
					font-size: 18px;
					font-weight: 500;
					color: rgba(13, 135, 235, 1);
				}
			}
		}
	}
}