.order-shipment-history{
  width: 100%;
  min-height: 100%;
  padding:0px 10px 10px 10px;
  background-color: #e9eef3;
  overflow: hidden;
  .content{
      background-color: #ffffff;
      border-radius: 4px;
  }
}