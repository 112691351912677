.merchant-manage {
	width: 100%;
	height: 100%;
	background-color: #e9eef3;
	padding: 0px 10px 10px 10px;

	.merchant-manage-content {
		background-color: #ffffff;
		padding: 20px;

		.filter-content {
			background-color: #f5f7fa;
			border-radius: 10px;
			padding: 10px 20px;
			margin: 0px 0px 30px 0px;
			display: flex;
			align-items: center;

			.icon-content {
				min-width: 60px;
				height: 60px;
				border-radius: 100%;
				background-color: #2589ff;
				display: flex;
				justify-content: center;
				align-items: center;

				.iconfont {
					font-size: 40px;
				}
			}

			.text-content {
				font-size: 14px;
				color: #606266;
				margin-left: 8px;
				margin-right: 28px;

				.title {
					font-size: 20px;
					color: #303133;
					font-weight: bold;
				}
			}
		}

		.table-header {
			margin-bottom: 8px;
			font-size: 20px;
			color: #303133;
			font-weight: bold;
		}

		.changeInfo {
			margin-left: 8px;
			cursor: pointer;
			color: #1890ff;
		}

		.specialInputWrap {
			.ant-input {
				.ant-calendar-range-picker-input {
					width: auto !important;
					display: unset !important;
				}
			}
		}
	}

	.tips {
		.iconfont {
			color: #0e8fdd !important;
			position: relative;
			top: 60px;
			left: -60px;
			font-size: 30px !important;
		}

		animation: bounce 1s infinite;

		img {
			transform: rotate(90deg)
		}
	}

	.seeHelp {
		color: #1790FF;
		margin-left: 10px;
		cursor: pointer;
	}

	@keyframes bounce {

		from,
		20%,
		53%,
		80%,
		to {
			animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
			transform: translate3d(0, 0, 0);
		}

		40%,
		43% {
			animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
			transform: translate3d(0, -30px, 0);
		}

		70% {
			animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
			transform: translate3d(0, -15px, 0);
		}

		90% {
			transform: translate3d(0, -4px, 0);
		}
	}
}

.red {
	color: #ff5d5d;
}