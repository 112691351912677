.login {
    min-width: 1000px;
    min-height: 100vh;
    display: flex;
    .bg-login{
      width: 54%;
      background: url("../images/bg-login.png") no-repeat;
      background-size: cover;
      background-position: center;
    }
    .content {
      flex: 1;
      background: #F7F8FA;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .form-box{
      width: 364px;

      .logo_box {
        color: #15161B;
        display: flex;
        align-items: center;

        .logo_text {
          font-size: 36px;
          font-weight: 700;
          font-style: italic;
          font-family: monospace;
        }

        .logo_text_des {
          font-size: 20px;
          font-weight: 600;
          margin-left: 10px;
          margin-top: 5px;
        }
      }
    }
    .text-login-in-store{
      font-size: 22px;
      color: #15161B;
      padding-top: 50px;
      padding-bottom: 24px;
      font-weight: 500;
    }
    .login-form {
      .ant-input, .login-form-button {
        width: 364px;
        height: 50px;
        border-radius: 2px;
        border-color: #ebedf0;
      }
      .login-form-button {
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 1px;
        background: #155bd4;
      }
      .ant-input-affix-wrapper {
        width: 364px;
      }
      .ant-input-affix-wrapper .ant-input:not(:first-child){
        padding-left: 58px;
      }
    }
  
  }
  