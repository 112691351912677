.modify_password{
  width: 100%;
  height: 100%;
  background-color: #e9eef3;
  padding: 0px 10px 10px 10px;
  .content{
    padding:20px;
    background-color: #ffffff;
    border-radius: 4px;
    text-align: center;
  }
  .ant-row{
    display:flex!important;
    width: 300px;
    margin: 0px auto;
  }
  .ant-form-item-label {
    width:100px;
    display: inline-block!important;
    overflow: hidden;
    line-height: 39.9999px;
    white-space: nowrap;
    text-align: right!important;
    vertical-align: middle;
  }
  .ant-form-item-control-wrapper{
    display: inline-block!important;
  }
  .ant-row:last-child .ant-form-item-control-wrapper{
    margin: 0px auto;
  }
}