.live-list{
    width:100%;
    min-height:100%;
    background-color: #e9eef3;
    padding:0px 10px 10px 10px;
    .content{
        background: #ffffff;
        padding:20px;
        border-radius:4px;
    }
    .filter-action .text-note{
        margin-left:20px;
    }
    .action{
        text-align: end;
    }
}
