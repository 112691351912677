.shop-manage {
	width: 100%;
	min-height: 100%;
	background-color: #ffffff;
	overflow: hidden;
	.content {
		width: 600px;
		border-radius: 4px;
		padding: 10px;
		.title {
			display: inline-block;
			padding: 0 6px;
			margin-right: 12px;
			border-left: 3px solid #2589ff;
			font-size: 14px;
			line-height: 14px;
		}
		.text-note2 {
			font-size: 14px;
			color: #2589ff;
		}
		.text-note {
			font-size: 12px;
			color: #909399;
			line-height: 20px;
			text-align: justify;
			margin-bottom: 0;
		}
		.flex-right {
			display: flex;
			justify-content: flex-end;
			padding-right: 10px;
		}
	}
	.saveBtn {
		display: block;
		margin: 0 auto 24px;
	}
}

.ant-upload-list-item-info {
	background-color: rgba(0, 0, 0, 0.08);
}
