.message {
	position: fixed;
	z-index: 999;
	opacity: 1;

	&.center {
		width: 60%;
		max-width: 1150px;
		bottom: 0;
		top: 45%;
		right: 0;
		left: 55%;
		transform: translateX(-50%) translateY(-50%);
		animation: zoomInUp 200ms ease-in-out;
	}

	.main-content {
		width: 100%;
		height: 560px;
		box-shadow: 0px 0px 4px 0px rgba($color: #000000, $alpha: 0.3);
		border-radius: 4px;
		background-color: #fff;
		display: flex;
		position: relative;

		.close-content {
			position: absolute;
			right: 5px;
			top: 5px;
			cursor: pointer;
			width: 24px;
			height: 24px;
			text-align: center;
			line-height: 24px;
			background-color: #fff;
			border-radius: 100%;
		}

		.left-content {
			width: 240px;
			height: 100%;
			border-right: 1px solid #ddd;
			padding-bottom: 10px;
			display: flex;
			flex-direction: column;
			background-color: #ebeef5;

			.title {
				margin-bottom: 0;
				height: 50px;
				line-height: 50px;
				background-color: #0d87eb;
				padding-left: 10px;
				border-top-left-radius: 4px;
				color: #fff;
				cursor: move;
			}

			.no-user {
				text-align: center;
				color: #999;
				margin-top: 20px;
			}

			.user-list {
				width: 230px;
				height: 100%;
				overflow: hidden;
				cursor: pointer;

				&:hover {
					overflow: auto;
				}

				&::-webkit-scrollbar {
					width: 6px;
					background-color: #fff;
				}

				&::-webkit-scrollbar-thumb {
					border-radius: 10px;
					background-color: #ddd;
				}

				&::-webkit-scrollbar-track {
					border-radius: 10px;
					background-color: #fff;
				}

				.user-item {
					width: 100%;
					padding: 10px;
					border-bottom: 1px solid #fff;
					display: flex;

					&.active {
						background-color: #d3d5dc;
					}

					.text-content {
						width: 100%;
						margin-left: 8px;
						display: flex;
						flex-direction: column;

						.line1 {
							display: flex;
							align-items: center;
							margin-bottom: 6px;

							.user-name {
								display: block;
								font-weight: bold;
								color: #333;
								font-size: 14px;
								width: 90px;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
							}

							.time {
								font-size: 12px;
								color: #999;
								text-align: right;
								width: 60px;
							}
						}

						.h20 {
							height: 20px;

							.cg-text {
								font-size: 14px;
								color: #999;
								display: inline-block;
								width: 60px;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
							}
						}

						.content {
							height: 20px;
							font-size: 14px;
							color: rgba(51, 51, 51, 0.5);
							display: inline-block;
							width: 100px;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}

						.red {
							display: inline-block;
							font-size: 14px;
							color: #ec4958;
							overflow: hidden;
						}
					}
				}

				.no-more {
					font-size: 12px;
					color: #999;
					text-align: center;
					margin-bottom: 0;
				}
			}
		}

		.right-content {
			width: 100%;
			height: 100%;
			background-color: #fff;

			.item-info {
				height: 100%;

				&.hide {
					visibility: hidden;
				}
			}

			.right-top {
				height: 75%;
				display: flex;
				flex-direction: column;

				.title {
					line-height: 50px;
					height: 50px;
					padding-left: 20px;
					font-size: 16px;
					font-weight: bold;
					background-color: #ebeef5;
					width: 100%;
				}

				.cm-list {
					padding: 30px 20px 10px 20px;
					height: 100%;
					overflow: hidden;

					&:hover {
						overflow: auto;
					}

					&::-webkit-scrollbar {
						width: 0px;
						background-color: #f4f4f4;
					}

					&::-webkit-scrollbar-thumb {
						border-radius: 10px;
						background-color: #ddd;
					}

					&::-webkit-scrollbar-track {
						border-radius: 10px;
						background-color: #f4f4f4;
					}

					.cm-item {
						margin-bottom: 20px;

						.time-title {
							color: rgba(85, 85, 85, 0.5);
							font-size: 12px;
							text-align: center;
							margin-bottom: 20px;
						}

						.main-message {
							display: flex;
							align-items: center;

							.message-content {
								margin-left: 10px;
								position: relative;

								.content-info {
									background-color: #f5f7fa;
									padding: 6px 12px;
									border-radius: 4px;
									font-size: 14px;
									position: relative;

									&::after {
										position: absolute;
										content: "";
										width: 0;
										height: 0;
										right: 98%;
										top: 40%;
										border-top: 5px solid transparent;
										border-right: 7px solid #f5f7fa;
										border-bottom: 5px solid transparent;
									}
								}

								.send-fail {
									position: absolute;
									left: 0;
									bottom: -16px;
									font-size: 12px;
									color: #ec4958;
								}
							}

							&.merchant {
								flex-direction: row-reverse;

								.cm-item-avatar {
									flex-shrink: 0;
								}

								.content-info {
									margin-left: 0px;
									margin-right: 10px;
									background-color: #0d87eb;
									color: #fff;

									&::after {
										position: absolute;
										content: "";
										width: 0;
										height: 0;
										right: 0;
										left: 98%;
										top: 40%;
										border-right: 0;
										border-top: 5px solid transparent;
										border-left: 7px solid #0d87eb;
										border-bottom: 5px solid transparent;
									}
								}
							}
						}
					}
				}
			}

			.input-content {
				position: relative;

				.ant-form-item {
					margin-bottom: 0;
				}

				#message_form_text {
					resize: none;
					height: 140px;
					margin-bottom: 0;
					border-radius: 0;
					border-width: 1px 0 0 0;
				}

				.send-btn {
					position: absolute;
					bottom: 12px;
					right: 12px;
					width: 104px;
					text-align: center;
					height: 36px;
					line-height: 36px;
					border-radius: 100px;
					color: #fff;
					background-color: #0d87eb;
					font-size: 14px;
					cursor: pointer;
				}
			}
		}
	}

	.hide {
		overflow: hidden;
		visibility: hidden;
		height: 0;
		opacity: 0;
		margin: 0;
		display: none;

		div {
			overflow: hidden;
			visibility: hidden;
			height: 0;
			opacity: 0;
			margin: 0;
			display: none;
		}
	}
}

.messageBtnWrap {
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 9;

	.message-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 200px;
		height: 60px;
		background-color: #232323;
		;
		color: #ffffff;
		cursor: pointer;

		p {
			margin-left: 9px;
			margin-bottom: 0;
			font-size: 16px;
			font-weight: 400;
		}
	}

	.newMessageTip {
		position: absolute;
		right: -293px;
		top: 6px;
		width: 279px;
		height: 40px;
		background: #E9F3FF;
		box-shadow: 0px 0px 2px 0px rgba(233, 243, 255, 1);
		border-radius: 2px;
		text-align: center;
		line-height: 40px;

		.sanjiao {
			position: absolute;
			left: -5px;
			top: 50%;
			transform: translateY(-50%) rotate(-45deg);
			width: 0;
			height: 0;
			border: 5px solid #E9F3FF;
		}

		.messageTip {
			font-size: 16px;
			color: #333333;
			font-weight: 400;
		}

		.seeNow {
			cursor: pointer;
			margin-left: 17px;
			color: #1790FF;
			font-size: 16px;
			font-weight: 400;
		}

		.icon-guanbianniu {
			cursor: pointer;
			position: absolute;
			right: 0px;
			top: 0px;
			transform: translate(50%, -50%);
			color: #bbbbbb;
		}
	}
}

.olineServiceWrap {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	right: 32px;
	bottom: 55px;
	z-index: 999;

	.robotImg {
		width: 75px;
		height: 77px;
	}

	.olineServiceContent {
		position: relative;
		width: 94px;
		height: 35px;
		background: #1379D8;
		border-radius: 2px;
		display: flex;
		justify-content: center;
		align-items: center;

		.icon-kefu1 {
			font-size: 18px;
			margin-right: 4px;
		}

		.text {
			font-size: 12px;
			color: #ffffff;
			font-weight: 400;
		}

		.popTip {
			position: absolute;
			left: -196px;
			top: -6px;
			width: 170px;
			height: 30px;
			padding: 8px;
			background-color: #3CA7FF;
			border-radius: 2px;
			box-shadow: 0px 0px 4px 0px #3ca7ff;
			line-height: 14px;
			font-size: 12px;
			color: #ffffff;
			font-weight: 400;
			box-sizing: content-box;

			.sanjiao {
				position: absolute;
				top: 50%;
				right: -5px;
				;
				transform: translateY(-50%) rotate(45deg);
				width: 0;
				height: 0;
				border: 5px solid #3CA7FF;

			}
		}
	}
}

@keyframes zoomInUp {
	from {
		opacity: 0;
		-webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
		transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
		-webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}

	60% {
		opacity: 1;
		-webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
		transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
		-webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
		animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
	}
}