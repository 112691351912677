.driver-manage{
  width:100%;
  min-height:100%;
  background-color: #e9eef3;
  padding:0px 10px 10px 10px;
  .content{
    width:100%;
    min-height:914px;
    padding:20px;
    background-color: #ffffff;
    border-radius: 4px;
    .bonus-recharge-title{
      background-color: #f5f7fa;
      border-radius: 10px;
      height:100px;
      padding:20px 0px;
      margin-bottom: 20px;
      .iconfont{
        color: #ffffff;
        width: 60px;
        height: 60px;
        background-color: #f5a623;
        display:inline-block;
        font-size:30px;
        border-radius: 30px;
        text-align: center;
        line-height:60px;
        margin-right:8px;
        margin-left:20px;
      }
      .bonus-recharge-title-sign{
        font-family: PingFangSC-Semibold;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #303133;
      }
      .text-note{
        text-decoration: underline;
      }
    }
    .recharge-content{
      padding:44px 0px 44px 0px;
      text-align: center;
      .recharge-kind:last-child{
        margin-top:26px;
      }
      .ant-btn-round{
        width:100px;
        margin-right:12px;
      }
      .ant-btn-round:last-child{
        margin-right:0px;
      }
      .ant-input{
        width:324px;
      }
      .kind-title{
        display:inline-block;
        width:98px;
        text-align: right;
      }
    }
    .action{
      text-align: center;
    }
  }
  .iconfont{
    color: #0d87eb;
    cursor: pointer;
    margin-left:14px;
  }
}
.driver-modal{
  .ant-form-item-label {
    display: inline-block!important;
    overflow: hidden;
    line-height: 30px;
    white-space: nowrap;
    text-align: right!important;
    vertical-align: middle;
  }
  .ant-form-item-control-wrapper{
    display: inline-block!important;
  }
}
.driver-info{
  width:200px!important;
}