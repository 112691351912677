p {
    margin: 0;
    padding: 0;
}

.RefundOrderDetail {
    width: 100%;
    padding: 0 10px;

    .content {
        padding: 20px;
        background-color: #ffffff;
        border-radius: 4px;

        &.top {
            position: relative;
            padding: 24px;

            .refundSn {
                font-size: 18px;
                color: #333333;
            }

            .status {
                margin-top: 12px;
                font-size: 18px;
                color: #1790FF;

                .iconfont {
                    margin-right: 4px;
                    color: #1790FF;
                }

                .goDetail {
                    cursor: pointer;
                    margin-left: 20px;
                    font-size: 14px;
                    color: #666666;
                }
            }

            .statusDesc {
                margin-top: 10px;
                font-size: 14px;
                color: #333333;
            }

            .btnWrap {
                margin-top: 22px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            .personAndTime {
                position: absolute;
                right: 24px;
                top: 24px;
                font-size: 16px;
                color: #333333;
            }
        }

        &.mid {
            margin-top: 10px;

            .midWrap {
                padding: 20px 12px;
                display: flex;

                div {
                    flex: 1;

                    p {
                        margin-top: 6px;
                        font-size: 14px;
                        color: #333333;

                        &.gray {
                            color: #666666;
                        }

                        &.noMTop {
                            margin-top: 0;
                        }

                        &.goodsName {
                            max-width: 260px;
                            overflow: hidden;

                            white-space: nowrap;
                            word-break: break-all;
                            text-overflow: ellipsis;
                        }

                        &.refundReason {
                            margin-top: 58px;
                        }

                        .juice {
                            color: #DC5D5C;
                        }
                    }

                    .midTitle {
                        margin-top: 0;
                        margin-bottom: 14px;
                        font-size: 16px;
                        font-weight: bold;
                        color: #333333;
                    }

                    &.refundGoods {
                        .goodsWrap {
                            display: flex;

                            .goodsImg {
                                margin-right: 20px;
                                width: 70px;
                                height: 70px;
                            }

                            .gray {
                                margin-top: 6px;
                            }
                        }
                    }
                }
            }
        }

        &.bot {
            margin-top: 10px;

            .record {
                margin-bottom: 10px;
                margin: 20px 10px;
                display: flex;

                .recordLeft {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .role {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 30px;
                        font-size: 16px;
                        font-weight: 500;
                        color: #FFFFFF;
                        background-color: #C2C2C2;
                    }

                    .line {
                        margin-top: 13px;
                        width: 1px;
                        flex-grow: 1;
                        background-color: #DFDFDF;
                    }
                }

                .recordRight {
                    margin-left: 12px;
                    flex-grow: 1;
                    margin-bottom: 20px;
                    padding: 20px 26px;
                    background-color: #F9F9F9;

                    p {
                        display: flex;
                        flex-direction: column;
                        font-size: 16px;
                        color: #333333;
                    }
                }
            }
        }
    }
}