.shopData {
	position: relative;
	overflow: hidden;
	width:100%;
	border-radius: 4px;
	background-color: #ffffff;
	.title {
		margin: 20px 0 0 20px;
		padding-left: 8px;
		border-left: 3px solid #1790FF;
		font-size: 14px;
		color: #303133;
		font-weight: bold;
		line-height: 20px;
		.updateTime {
			margin-left: 12px;
			font-size: 12px;
			color: #999999;
		}
	}
	.wrap {
		margin-top: 63px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		ul {
			&.shopUl {
				width: 75%;
				li {
					width: 33.3%;
				}
			}
			&.orderUl {
				width: 25%;
				li {
					width: 100%;
					cursor: pointer;
					.datayesterDay {
						visibility: hidden;
					}
				}
			}
			display:flex;
			flex-wrap: wrap;
			li {
				margin-bottom: 47px;
				flex-shrink: 0;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				i {
					display: inline-block;
					width: 32px;
					height: 32px;
					line-height: 32px;
					font-size: 32px;
				}
				.dataTitle {
					margin-top: 21px;
					margin-bottom: 0;
					font-size: 14px;
					color: #333333;
					line-height: 20px;
					font-weight: bold;
				}
				.dataTotal {
					margin-top: 10px;
					margin-bottom: 0;
					font-size: 26px;
					font-weight: bold;
					line-height: 37px;
					color: #333333;
				}
				.datayesterDay {
					margin-top: 10px;
					font-size: 14px;
					color: #999999;
					line-height: 20px;
				}
			}
		}
	}
	.line {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 25%;
		width: 1px;
		height: 320px;
		background-color: #E8EAEC;
	}
	.ant-input {
		width:364px;
		height:50px;
		border-radius: 100px;
	}
}