.merchant-manage {
  width: 100%;
  height: 100%;
  background-color: #e9eef3;
  padding: 0px 10px 10px 10px;
  .merchant-manage-content {
    background-color: #ffffff;
    border-radius: 4px;
    padding: 20px;
    .filter-content {
      background-color: #ffffff;
      border-radius: 10px;
      border: solid 1px #e4e7ed;
      border-radius: 10px;
      padding: 23px 28px;
      display: flex;
      align-items: center;
      .text-content {
        font-size: 14px;
        color: #606266;
        margin-left: 8px;
        margin-right: 28px;
        .title {
          font-size: 20px;
          color: #303133;
          font-weight: bold;
          margin-bottom: 10px;
        }
      }
    }
    .errMessage {
      color: red;
      font-size: 16px;
      margin-bottom: 30px;
      padding-left: 23px;
    }
    .form-content {
      .flex-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        .text-info {
          width: 100%;
          line-height: 10px;
          text-align: center;
        }
      }
      .ant-collapse-header {
        background-color: #ebeef5;
        border-radius: 100px;
        position: relative;
        padding-left: 30px;
        font-weight: bold;
        &::before {
          content: "";
          position: absolute;
          width: 4px;
          height: 16px;
          background-color: #0d87eb;
          border-radius: 100px;
          top: calc(50% - 8px);
          left: 20px;
        }
      }
      .panel-item {
        padding-top: 20px;
      }
      .ant-select,
      input {
        width: 250px;
        display: block;
        &.mb15 {
          margin-bottom: 15px;
        }
      }
      .text-note {
        height: auto;
        font-size: 12px;
        color: #909399;
      }
      .flex {
        display: flex;
        align-items: center;
        .text-note {
          line-height: 20px;
          width: 150px;
          margin-left: 8px;
        }
      }
      .ant-row {
        margin-bottom: 10px;
        margin-top: 2px;
      }
      .textarea {
        border-radius: 10px;
        resize: none;
        height: 100px;
      }
      .ant-upload-text {
        font-size: 13px;
        margin-top: 10px;
      }
    }
    .btn-content {
      width: 100%;
      .submit-btn {
        width: 100%;
        height: 52px;
        line-height: 52px;
        background-color: #0d87eb;
        font-size: 20px;
        border-radius: 0 !important;
      }
    }
  }
  .ant-form-item-label {
    text-align: right !important;
  }
}

.ml10 {
  margin-left: 10px;
}
