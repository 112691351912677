.assets-manage{
    .recharge-link{
        font-size:14px;
        font-family:PingFang SC;
        font-weight:400;
        line-height:20px;
        color:rgba(13,135,235,1);
        opacity:1;
        margin-left:10px;
        cursor: pointer;
    }
    .btn-recharge{
        margin-left:10px;
        position:relative;
        top:-5px;
    }
    .integral-dashboard{
        margin-bottom:20px;
    }


}
