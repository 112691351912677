.order-detail {
	.ant-steps-item-description {
		font-size: 12px;
	}

	width:100%;
	min-height:100%;
	background-color: #e9eef3;
	padding:0px 10px 10px 10px;

	.content {
		.order-detail-info {
			display: flex;
			background-color: #e9eef3;
			margin-bottom: 10px;

			.order-info {
				flex: 1;
				background-color: #ffffff;
				border-radius: 4px;
				padding: 20px;
			}

		}

		.refund_order_list {
			background-color: #ffffff;
			border-radius: 4px;
			padding: 20px;
			margin-bottom: 10px;

		}

		.order-info:first-child {
			margin-right: 10px;
		}

		.order-step {
			background-color: #ffffff;
			height: 120px;
			margin-bottom: 10px;
			border-radius: 4px;
			padding: 20px;
		}

		.order-steps {
			width: 812px;
			height: 51px;
			margin: 0 auto;
		}

		.order-goods {
			background-color: #ffffff;
			border-radius: 4px;
			padding: 20px;

			.ant-table-wrapper {
				.ant-table-tbody {
					.goodsInfoWrap {
						display: flex;
						justify-content: flex-start;
						align-items: center;

						.goodsImg {
							width: 48px;
							height: 48px;
						}

						.nameAndId {
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: flex-start;

							p {
								margin: 0;

								&.name {
									max-width: 160px;
									overflow: hidden;
									white-space: nowrap;
									word-break: break-all;
									text-overflow: ellipsis;
								}
							}
						}
					}
				}
			}
		}

		footer {
			height: 50px;
			background-color: #ffffff;
			border: solid 1px #e4e7ed;
			text-align: right;
			font-family: PingFangSC-Regular;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0px;
			color: #606266;
			padding-right: 10px;
			line-height: 50px;

			span {
				margin-right: 40px;
			}

			.iconfont {
				margin-left: 4px;
				cursor: pointer;

			}
		}

		.iconfont {
			color: #0d87eb;
		}

		.goods-title {
			margin-bottom: 10px;
		}

		.btn-refuse {
			margin-left: 10px;
		}

	}

	.order-remind {
		width: 530px;
		height: 26px;
		background-color: #fef0f0;
		border-radius: 100px;
		border: solid 1px #ffdcdc;
		font-family: PingFangSC-Regular;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		line-height: 26px;
		color: #ff3535;
		text-indent: 10px;
		margin: 15px 0px;
	}

	.record-action {
		background-color: #f5f7fa;
		border-radius: 10px;
		padding: 12px 20px;
		margin-bottom: 20px;
	}

	.icon-xiangyoushuangjiantou {
		margin: 0px 10px;
	}

	p {
		margin-bottom: 10px;
	}

	.user-info {
		span:last-child {
			margin-right: 100px;
			float: right;
		}

	}
}

.add-record-modal {
	width: 520px;
	height: 400px;

	.ant-input {
		border-radius: 8px !important;
		width: 417px;
		height: 200px;
	}
}