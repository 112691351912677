.order-manage,
.market-tab {
  width: 100%;
  min-height: 100%;
  background-color: #e9eef3;
  padding: 0px 10px 10px 10px;
  .goods-id {
    width: 200px;
  }
  .ant-form-item {
    margin-right: 20px !important;
  }
  .goods-name {
    width: 420px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
  .order-content {
    padding-top: 20px;
    border-radius: 4px;
    background-color: #ffffff;
    .filter-content {
      padding: 20px;
      background-color: #f5f7fa;
      border-radius: 10px;
      margin-bottom: 30px;
      .action {
        margin-top: 20px;
        .ant-btn:first-child {
          margin-right: 10px;
        }
      }
    }
  }

  .ant-select-selection {
    border-radius: 100px !important;
    width: 200px !important;
  }
  .table-action {
    width: 79px;
  }
  .text-note {
    margin-left: 10px;
    &.ml0 {
      margin-left: 0;
    }
  }
  .content {
    padding: 0px 20px 20px 20px;
  }
}
.order-list-content {
  .text-note {
    white-space: nowrap;
    width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .text-right {
    text-align: right;
  }
  .row-header {
    padding: 16px 0 0 0 !important;
    border: none;
    .mr20 {
      margin-right: 146px;
    }
  }
  .table-order-info {
    border: 1px solid rgba(232, 234, 236, 1);
    background-color: #f7f8fa !important;
    font-weight: 500;
    font-size: 14px;
    padding: 10px;
  }
  .order-goods-info {
    display: flex;
    .goods-name-sku {
      flex: 1;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .sku{
        height: 21px;
      }
      .order-goods-name {
        max-width: 240px;
        color: #1790ff;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .ant-table-tbody .ant-table-row-cell-break-word {
    border-right: 1px solid #e8e8e8;
    &:first-child{
      border-left: 1px solid #e8e8e8;
    }
  }
  .ant-table-expanded-row {
    td {
      border: none;
      padding: 0 !important;
    }
  }
  .remark-line {
    padding-top: 8px;
    .remark-text {
      background: rgba(245, 36, 47, 0.1);
      border-radius: 2px;
      border: 1px dashed rgba(255, 182, 186, 1);
      padding: 8px 20px;
    }
  }
}
