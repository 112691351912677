.template {
  width: 100%;
  min-height: 100%;
  background-color: #e9eef3;
  padding: 0px 10px 10px 10px;
  .template-content {
    background-color: #fff;
    padding: 20px;
    .shopDataTitle {
      font-size: 14px;
      height: 20px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #303133;
      font-family: PingFangSC-Semibold;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .titleSign {
        width: 4px;
        height: 100%;
        background-color: #0d87eb;
        border-radius: 100px;
        margin-right: 10px;
      }
    }
  }
}
