p {
    margin: 0;
    padding: 0;
}

.fissionCouponSetting {
    width: 100%;
    padding: 0 10px;

    .content {
        padding: 20px;
        background-color: #ffffff;
        border-radius: 8px;

        .addProduct {
            cursor: pointer;
            width: 100px;
            height: 100px;
            border: 1px dashed #eeeeee;
            margin-bottom: 32px;
            line-height: 100px;
            text-align: center;
            color: #1890ff;
        }

        .ant-input-affix-wrapper {
            width: auto;
        }

        .ant-col-2 {
            text-align: left;
            margin-right: 10px;
        }

        .innerLabel {
            margin-right: 12px;
        }

        .inlinePop {
            display: inline-block;
        }

        .drag-sort {
            flex-wrap: wrap;
        }

        .text-note {
            color: #909399;
            font-size: 12px;
        }

        .ant-form-extra {
            line-height: 2.5;
        }

        .submitBtnWrap {
            padding: 36px 0;
            text-align: center;
        }
    }
}

.EXTEXT {
    width: 240px;
    height: 120px;
}