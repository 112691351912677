.popOverTips {
    position: relative;
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;

    .innerText {
        color: #333333;
        font-size: 14px;
    }

    .quesIcon {
        font-size: 12px;
        margin-left: 4px;
        // position: absolute;
        // top: 50%;
        // transform: translateY(-50%);
        color: #1890ff;
    }
}