.goods-detail {
    .info-collapse{
        margin-left:10px;
    }
    .no-show{
        display:none
    }
    width: 100%;
    min-height: 100%;
    background-color: #e9eef3;
    .goods-content {
        background-color: #ffffff;
        padding: 20px;
        .goods-title {
            margin-bottom: 21px;
        }
        .ant-input {
            width: 400px;
            height: 36px;
            border-radius: 100px;
        }
        textarea {
            height: 100px !important;
            background-color: #ffffff !important;
            border-radius: 10px !important;
            border: solid 1px #dcdfe6 !important;
        }
        .ant-select {
            width: 200px;
            height: 36px;
        }
        .select-option {
            width: 80px;
        }
    }
    .panel-title {
        width: 112px;
        font-family: PingFangSC-Semibold;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #303133;
    }
    .anticon-caret-right svg {
        color: #0d87eb !important;
    }
    .title-sign {
        width: 4px;
        height: 16px;
        background-color: #0d87eb;
        border-radius: 100px;
        display: inline-block;
        margin-right: 10px;
    }
    .ant-collapse .ant-collapse-item .ant-collapse-header .anticon {
        left: initial;
        right: 16px;
    }
    .goods-add-button-box {
        height: 86px;
        background-color: #ffffff;
        box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.07);
        text-align: center;
        line-height: 86px;
        margin-top: 30px;
        cursor: pointer;
    }
    .goods-add-input {
        width: 100px !important;
        height: 36px;
    }
    .goods-info {
        width: 640px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #606266;
    }
    .flex-img {
        display: flex;
    }
    .shipment-fee {
        width: 200px !important;
        margin-left: 10px;
        margin-right: 10px;
    }
    .radio-block {
        display: block;
        height: 60px;
        line-height: 30px;
    }
    .buy-limit {
        width: 118px !important;
        height: 36px !important;
    }
}
