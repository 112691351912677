p {
    margin: 0;
    padding: 0;
}

.cycleSetting {
    width: 100%;
    padding: 0 10px;

    .content {
        padding: 20px;
        background-color: #ffffff;
        border-radius: 4px;

        .ant-col-3 {
            padding-right: 28px;
        }

        .ant-select {
            width: 88px;
            margin: 0 8px;
        }

        .ant-table-tbody {
            .ant-input {
                margin-right: 8px;

                &.phase {
                    width: 88px;
                }

                &.price {
                    width: 106px;
                }

                &.sku {
                    width: 106px;
                }
            }
        }

        .ant-table-footer {
            text-align: center;
            color: #007aff;

            span {
                cursor: pointer;
            }
        }

        .selectArea {
            overflow: hidden;
            cursor: pointer;
            width: 90px;
            height: 90px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: 1px dashed #D9D9D9;
            border-radius: 2px;

            .anticon {
                color: #1791FF;
                font-size: 12px;
            }

            .goods {
                width: 90px;
            }
        }

        .btnWrap {
            margin-top: 60px;
            display: flex;
            justify-content: center;
            align-items: center;

            .save {
                margin-right: 8px;
            }
        }
    }
}