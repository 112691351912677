.market-data{
    .market-info{
        display:flex;
        justify-content: space-around;
        height:126px;
        border:1px solid rgba(220,223,230,1);
        opacity:1;
        border-radius:4px;
        margin-bottom:20px;
        align-items:center;
        .market-info-data{
            height:45px;
            font-size:32px;
            font-family:PingFang SC;
            font-weight:bold;
            line-height:45px;
            color:rgba(48,49,51,1);
            opacity:1;
            text-align: center;
        }

        .market-info-title{
            height:20px;
            font-size:14px;
            font-family:PingFang SC;
            font-weight:400;
            line-height:20px;
            color:rgba(48,49,51,1);
            opacity:1;
        }
    }
    .pickerWrapWrap {
        display: flex;
        justify-content: center;
        align-items: center;
        .label {
            font-size: 12px;
            color: #333333;
        }
        .pickerWrap {
            margin-left: 12px;
        }
    }
    .iconfont{
        color:#0D87EB;
        font-size:12px;
    }

}
