.goods-remind{
  .title{
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }
  .list{
    height: 200px;
    overflow: auto;
  }
}