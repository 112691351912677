.card-content {
  padding-bottom: 70px;
  .bg-list {
    display: flex;
    margin-top: 10px;
    margin-left: 26px;
    .bg-item {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      position: relative;
      border: 1px solid rgba(220, 223, 230, 1);
      &.active {
        &::before {
          content: "";
          height: 0;
          width: 0;
          border-bottom: 10px solid #0d87eb;
          border-top: 10px solid transparent;
          border-left: 10px solid transparent;
          position: absolute;
          right: 0;
          bottom: 0;
        }
        &::after {
          content: "";
          position: absolute;
          right: 1px;
          bottom: 1px;
          height: 6px;
          width: 3px;
          border-bottom: 1px solid #fff;
          border-right: 1px solid #fff;
          transform: rotate(45deg);
        }
      }
    }
  }
  .show-content {
    position: absolute;
    width: 335px;
    padding-bottom: 15px;
    background: rgba(245, 247, 250, 1);
    border: 2px solid rgba(226, 229, 234, 1);
    opacity: 1;
    border-radius: 4px;
    top: 200px;
    left: 55%;
    .show-title {
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      color: rgba(48, 49, 51, 1);
      opacity: 1;
      transform: translateY(-50%);
      text-align: center;
    }
  }
  .card-info {
    width: 305px;
    border-radius: 8px;
    position: relative;
    padding: 10px;
    font-size: 10px;
    color: #555;
    .bg-image {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      opacity: 0.1;
      &.black {
        height: 100%;
        opacity: 0.2;
        z-index: 99;
        background-color: #000;
      }
    }
    .rules {
      position: absolute;
      right: 0;
      top: 10px;
      border-radius: 10px 0px 0px 10px;
      background: rgba(85, 85, 85, 0.9);
      width: 49px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      color: #fff;
    }
    .flex-col {
      display: flex;
      flex-direction: column;
      .logo {
        width: 30px;
        background-color: transparent;
      }
      .card-name {
        color: #ec234f;
        font-size: 12px;
        margin-top: 5px;
        font-weight: bold;
      }
      .label {
        color: #fff;
        align-self: center;
      }
      .less-num {
        font-size: 20px;
        color: #fff;
        align-self: center;
      }
      .card-equity {
        align-self: center;
        color: #f9a1ba;
        background-color: rgba(255, 255, 255, 0.4);
        padding: 3px 4px;
        border-radius: 100px;
        margin-top: 5px;
      }
      .flex {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        .card-status {
          color: #f9a1ba;
        }
      }
    }
    &.white {
      color: #fff;
      .card-equity {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.5);
      }
      .flex .card-status {
        color: #fff;
      }
    }
  }
  .upload {
    margin-top: 10px;
    margin-left: 26px;
    .upload-tips {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: rgba(192, 196, 204, 1);
      opacity: 1;
      max-width: 400px;
      white-space: normal;
    }
  }
  .block {
    display: block;
  }
  .mb10 {
    margin-bottom: 10px;
  }
  .input-short {
    margin: 0 5px;
  }
  .iconfont {
    width: 16px;
    height: 16px;
    color: #0d87eb;
    font-size: 14px;
    margin-left: 7px;
  }
  .card-textarea {
    border-radius: 0;
    width: 800px;
  }
  .hide {
    height: 0;
    visibility: hidden;
    opacity: 0;
    margin: 0;
  }
  .pay-table {
    border: 1px solid rgba(228, 231, 237, 1);
    border-right: 0;
    margin-top: 10px;
    display: flex;
    .table-col {
      border-right: 1px solid rgba(228, 231, 237, 1);
      .col-head {
        background-color: rgba(245, 247, 250, 1);
        height: 50px;
        line-height: 50px;
        padding-left: 10px;
        .text-danger {
          margin-right: 4px;
        }
      }
    }
    .col-body {
      height: 50px;
      line-height: 50px;
    }
  }
  .fixed-bottom {
    position: fixed;
    bottom: 0;
    left: 200px;
    width: calc(100% - 200px);
    background-color: #fff;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.07);
    border-radius: 0px 0px 4px 4px;
  }
  .content .select-goods {
    .tips {
      color: rgba(192, 196, 204, 1);
    }
    .integral-goods-add {
      display: flex;
      flex-direction: column;
      line-height: initial;
      background: rgba(250, 250, 250, 1);
      margin-top: 20px;
      position: relative;
      &:hover {
        .mask-text {
          display: block;
        }
      }
      .plus {
        font-size: 40px;
      }
      .goods-image {
        width: 90px;
        height: 90px;
        margin: 5px auto;
      }
      .mask-text {
        display: none;
        width: 100px;
        height: 100px;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        text-align: center;
        line-height: 100px;
      }
    }
  }
}
.create-update-card .ant-form-item {
  display: flex;
}
