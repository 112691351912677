.goods_manage {
  width: 100%;
  min-height: 100%;
  background-color: #e9eef3;
  padding: 0px 10px 10px 10px;
  overflow: hidden;
  .content {
    background-color: #ffffff;
    border-radius: 4px;
  }

  .goods-sidebar {
    width: 130px;
    height: 100%;
    margin-left: 200px;
    position: absolute;
    background-color: #ffffff;
    .content {
      width: 110px;
      height: 30px;
      background-color: #ebeef5;
      border-radius: 100px;
    }
  }
  .goods-publish {
    padding: 20px;
    .goods-img {
      float: left;
    }
    .pagenation {
      text-align: center;
    }
    .filter-content {
      background-color: #f5f7fa;
      border-radius: 10px;
      padding: 20px;
      margin: 10px 0px 30px 0px;
    }
    .goods-id {
      width: 200px;
      border-radius: 100px;
    }
    .goods-name {
      width: 420px;
      border-radius: 100px;
    }
    .goods-price-center {
      margin: 0px 5px 0px 5px;
    }

    .goods-info {
      position: relative;
      width: 200px;
      display: flex;
      .goods-info-img {
        flex: none;
        margin-right: 12px;
        img {
          border-radius: 10px;
        }
      }
      .goods-info-detail {
        flex: none;
        .change-goods-price {
          margin-left: 4px;
        }
        .goods-info-name {
          width: 100px;
          height: 20px;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #606266;
          line-height: 20px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
        }
      }
    }
    .ant-popover-inner-content {
      padding: 0px !important;
    }
    .iconfont {
      color: #0d87eb;
    }
    .anticon-edit {
      color: #cdcdcd;
      font-size: 16px;
      padding: 5px;
    }
    .mr20 {
      margin-right: 20px;
    }
    .no-wrap{
      white-space: nowrap;
      display: block;
    }
    .filter-action {
      margin-top: 20px;
      .text-note {
        margin-left: 4px;
      }
    }
    .action {
      margin-bottom: 10px;
      .choose-num {
        margin-left: 12px;
      }
    }
  }

  .goods-change-button {
    margin: 0px 10px 0px 10px !important;
  }
  .change-goods-name {
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .balance-pop {
    .ant-popover-inner-content {
      display: block;
    }
  }
  .option-list {
    display: flex;
    align-items: center;
  }
  .hide {
    visibility: hidden;
    overflow: hidden;
    opacity: 0;
  }
}
