p {
    margin: 0;
    padding: 0;
}

.refundDetail {
    width: 100%;
    padding: 0 10px;

    .content {
        padding: 20px;
        background-color: #ffffff;
        border-radius: 4px;

        .orderSn {
            font-size: 16px;
            font-weight: bold;
            margin: 24px 24px 24px 0;
        }
    }
}