.order-history{
  width:100%;
  padding:0px 20px 20px 20px;
  overflow: hidden;
  .filter-content{
    background-color: #f5f7fa;
    border-radius: 10px;
    padding:20px;
    margin-bottom: 38px;
    .action{
      margin-top:20px;
      .ant-btn{
        margin-right: 10px;
      }
    }
  }
  .action{
    margin-bottom: 10px;
    .choose-num{
      margin-left: 10px;
    }

  }
}