.bonus-settle{
  width:100%;
  min-height:100%;
  background-color: #e9eef3;
  padding:0px 10px 10px 10px;
  .content{
    position:relative;
    width:100%;
    background-color: #ffffff;
    border-radius: 4px;
    .balance{
      position:absolute;
      top:0px;
      right:20px;
      font-family: PingFangSC-Semibold;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #303133;
      line-height:43.99px;
    }
  }
}