.header {
  height: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .block-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .contact {
      display: flex;
      align-items: center;
      .btn {
        width: 86px;
        height: 32px;
        line-height: 32px;
        background-color: #ff9600;
        border-radius: 16px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
}
.pointer{
  cursor: pointer;
}
.footer {
  width: 100%;
  height: 170px;
  background: linear-gradient(90deg, #326dfa 0%, #4f6dfa 100%);
  display: flex;
  justify-content: center;
  .block-content {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      border-right: 1px solid rgba(255, 255, 255, 0.2);
      padding-right: 30px;
      .p1 {
        font-size: 40px;
        color: #fff;
      }
      .p2 {
        font-size: 20px;
        opacity: 0.5;
        color: #d1e6ff;
      }
    }
    .center {
      color: #fff;
      line-height: 26px;
    }
    .right {
      display: flex;
      color: #fff;
      text-align: center;
      .img-item {
        margin-left: 60px;
        &:first-child {
          margin-left: 0;
        }
      }
      img {
        margin-bottom: 8px;
      }
    }
  }
}
.block-content {
  width: 62.5%;
}
img.full-width {
  width: 100%;
}
.block5 {
  position: relative;
  .btn {
    position: absolute;
    left: 34%;
    bottom: 15%;
    width: 10rem;
    height: 3.3125rem;
    line-height: 3.3125rem;
    background: linear-gradient(90deg, #326dfa 0%, #4c95fd 100%);
    border-radius: 1.65625rem;
    text-align: center;
    font-size: 1.25rem;
    color: #fff;
    cursor: pointer;
  }
}
