p {
    margin: 0;
    padding: 0;
}

.invoiceManage {
    width: 100%;
    padding: 0 10px;

    .content {
        padding: 20px;
        background-color: #ffffff;
        border-radius: 8px;
    }
}