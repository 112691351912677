.cartStyle {
    padding-top: 48px;
    padding-bottom: 48px;
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 375px;
        height: 545px;
        margin-left: 140px;
        background-color: rgb(244, 244, 244);
        .leftMid {
            flex-grow: 1;
        }
    }
    .right {
        width: 356px;
        min-height: 400px;
        margin-right: 138px;
        background-color: rgb(244, 244, 244);

        .jumpTextArea {
            cursor: pointer;
            overflow: hidden;
            width: 200px;
            height: 28px;
            border-radius: 3px;
            line-height: 28px;
            border: 1px solid #d9d9d9;
            text-align: center;
            background-color: #ffffff;
        }

        .submit {
            display: block;
            width: 320px;
            margin: 24px auto 0;
        }
    }
}