.group-add{
  width:100%;
  min-height:100%;
  background-color: #e9eef3;
  padding:0px 10px 10px 10px;
  .content{
    background-color: #ffffff;
    padding:20px;
    .goods-title{
      margin-bottom: 20px;
    }
    .action{
      margin: 5px 0px 10px 0px;
    }
    .panel-title{
      width:400px;
    }
    .ant-calendar-picker-input{
      width:400px!important;
    }
  }
  .text-note{
    margin-left:10px;
  }
  .goods-id{
    width:200px;
    border-radius: 100px;
  }
  .goods-name{
    width:420px;
    border-radius: 100px;
  }
  .ant-input{
    width:200px !important;
    height: 36px;
    border-radius: 100px;
  }
  .ant-select-selection{
    border-radius: 100px;
  }
  .group-add-button-box{
    height: 86px;
    background-color: #ffffff;
    box-shadow: 0px -2px 8px 0px
    rgba(0, 0, 0, 0.07);
    text-align: center;
    line-height: 86px;
  }
  .anticon-caret-right svg{
    color:#0d87eb!important;
  }
}
.goods-info {
  position: relative;
  width: 300px;
  display: flex;
  .goods-info-img {
    flex: none;
    margin-right:4px;
  }
  .goods-info-detail {
    flex: none;
    .goods-info-name {
      width: 168px;
      height: 20px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #606266;
      line-height: 20px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
  }
}
.ant-input{
  border-radius: 100px;
}
.ant-collapse .ant-collapse-item .ant-collapse-header .anticon {
  left: initial;
  right: 16px;
}
.goods-modal{
  width: 696px!important;
  .ant-btn{
    border-radius: 100px;
  }
  .goods-info-img{
    margin-right:12px;
    img{
      border-radius: 10px;
    }
  }
}
.leader-modal{
  .ant-btn{
    border-radius: 100px;
  }
}
.modal-note{
  height: 26px;
  background-color: #fdf6ed;
  border-radius: 100px;
  border: solid 1px #ffe3bf;
  text-indent: 2px;
  line-height: 26px;
}
.modal-form{
  margin:14px 0px 14px 0px;
}