.after-sale-content {
	width: 100%;
	height: 100%;
	padding: 0px 20px 20px 20px;
	background-color: #ffffff;

	.filter-content {
		background-color: #f5f7fa;
		border-radius: 10px;
		padding: 20px;
		margin-bottom: 30px;

		.action {
			margin-top: 20px;

			.ant-btn {
				margin-right: 10px;
			}
		}
	}

	.text-note {
		white-space: nowrap;
		width: 100px;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.text-right {
		text-align: right;
	}

	.row-header {
		padding: 16px 0 0 0 !important;
		border: none;
	}

	.table-order-info {
		border: 1px solid rgba(232, 234, 236, 1);
		background-color: #f7f8fa !important;
		font-weight: 500;
		font-size: 14px;
		padding: 10px;
		display: flex;
		justify-content: space-between;
		flex-wrap: nowrap;
		overflow-x: scroll;
	}

	.order-goods-info {
		display: flex;

		.goods-name-sku {
			flex: 1;
			margin-left: 10px;
			display: flex;
			flex-direction: column;
			justify-content: center;

			.sku {
				height: 21px;
			}

			.order-goods-name {
				max-width: 240px;
				color: #1790ff;
				font-weight: 500;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}

	.ant-table-tbody .ant-table-row-cell-break-word {
		border-right: 1px solid #e8e8e8;

		&:first-child {
			border-left: 1px solid #e8e8e8;
		}
	}

	.ant-table-expanded-row {
		td {
			border: none;
			padding: 0 !important;
		}
	}

	.remark-line {
		padding-top: 8px;

		.remark-text {
			background: rgba(245, 36, 47, 0.1);
			border-radius: 2px;
			border: 1px dashed rgba(255, 182, 186, 1);
			padding: 8px 20px;
		}
	}

}