.integral-tab{
    width:100%;
    min-height:100%;
    background-color: #e9eef3;
    padding:0px 10px 10px 10px;
    .integral{
        padding:0px!important;
    }
    .content{
        wdith:100%;
        background: white;
    }
}
