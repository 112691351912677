.goods-drag-sort {
  position: relative;
  .button-activity-goods {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0 8px 8px 0;
    width: 66px;
    height: 66px;
    border-color: rgba(13, 135, 235, 1);
    color: rgba(13, 135, 235, 1);
  }
  .drag-sort {
    display: flex;
    &.pl100 {
      padding-left: 74px;
    }
    .img-content {
      min-width: 102px;
      width: 102px;
      height: 102px;
      border-radius: 4px;
      cursor: pointer;
      border: 1px solid #d9d9d9;
      padding: 5px;
      position: relative;
      margin: 0 8px 8px 0;
      &:hover {
        .img-close-icon {
          display: block;
        }
      }
      .img-close-icon {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%) translateY(-50%);
        color: red;
        font-size: 18px;
        z-index: 999;
      }
      .goods-detail-img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }
    .unsale {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.small {
      flex-wrap: wrap;
      .img-content {
        min-width: 66px;
        width: 66px;
        height: 66px;
      }
    }
  }
}
