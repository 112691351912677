.activity-setting {
    .iconfont {
        color: #2589ff;;
        font-size: 14px;
        margin-right: 10px;
    }
    width: 100%;
    min-height: 100%;
    background-color: #e9eef3;
    padding: 0px 10px 10px 10px;
    overflow:hidden;
    .content {
        display: flex;
        justify-content:space-between;
        background-color: #ffffff;
        border-radius: 4px;
        padding: 20px;
        .text-note {
            margin-right: 20px;
        }
        .component-add {
            margin-bottom: 26px;
            .ant-btn-background-ghost {
                margin-right: 16px;
            }
            .components {
                width:240px;
                height:100%;
                padding:12px 0px 0px 20px;
                background: rgba(245, 247, 250, 1);
                border-radius: 4px;
                border: 1px solid rgba(228, 231, 237, 1);
                .ant-btn{
                    width:160px!important;
                    margin:12px 12px 0px 0px;
                }
                .market-title{
                    margin-top:30px;
                }
                .text-note{
                    text-decoration: #2589ff;
                    text-decoration-line: underline;
                    margin-right:0px;
                }
            }
        }
        .title {
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFangSC;
            font-weight: 600;
            color: rgba(48, 49, 51, 1);
            line-height: 22px;
            margin-bottom: 8px;
        }
        .component-kind {
            font-family: PingFangSC-Regular, PingFangSC;
            font-weight: 400;
            color: rgba(96, 98, 102, 1);
        }
        .component-content {
            .component-edit{
                width:415px;
                background:rgba(245,247,250,1);
                border-radius:4px;
                border:1px solid rgba(228,231,237,1);
            }

        }
    }
    .card-goods{
        margin-top:13px;
    }
}
.component-edit{
    padding-bottom:32px;
    position: relative;
    .component-edit-content{
        height:100%;
    }
}
.component-edit-title{
    margin:12px 20px 9px 20px;
    font-size:16px;
    font-family:PingFangSC-Semibold,PingFang SC;
    font-weight:600;
    color:rgba(48,49,51,1);
    .text-danger{
        font-size:12px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(255,49,63,1);
        margin-left:8px;
    }
    .go-to{
        font-size:12px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(51,51,51,1);
        position:absolute;
        right:20px;
    }
}
.banner-add{
    .text-note{
        margin-left:8px;
    }
}
.component-content .ant-card-head {
    height: 40px;
    background-color: #e9eef3;
}
