.sideBar {
	margin-right: 10px;
	min-height: 100%;
	background-color: #fff;
	padding-bottom: 60px;
	font-family: PingFangSC-Semibold;

	.iconfont {
		margin-right: 16px;
		color: #999999;
	}

	.ant-menu-submenu-title,
	.AMenu {
		text-align: left;
		font-size: 14px;
		font-weight: bold;
		color: #999999;

		&.ant-menu-item-selected {
			color: #ffffff;

			.iconfont {
				color: #ffffff;
			}
		}

		&:hover {
			color: #878E9C;

			// .iconfont {
			// 	color: #878E9C;
			// }
		}

		&.ant-menu-item-selected:hover {
			color: #fff;
		}
	}

	.ant-menu.ant-menu-dark .ant-menu-item-selected,
	.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
		background-color: #fff;
		// color: #ffffff;
		border-radius: 10px;
	}

	.ant-menu.ant-menu-dark .ant-menu-inline .ant-menu-item{
		padding-left: 46px !important;
	}

	.ant-menu.ant-menu-dark .ant-menu-inline .ant-menu-item-selected {
		color: #fff;
		background-color: #2B282E;
    margin-left: 10px !important;
	}

	.ant-menu-dark .ant-menu-inline.ant-menu-sub .ant-menu-item:hover {
		color: #878E9C;
	}

	.ant-menu.ant-menu-dark .ant-menu-inline .ant-menu-item.ant-menu-item-selected:hover {
		color: #fff;
	}

	.ant-menu-submenu,
	.ant-menu-item {
		text-align: left;
		color: #999999;
		font-weight: bold;
	}

	.ant-menu-item,
	.ant-menu-submenu-inline {
		margin-bottom: 15px;
		color: #999999;
	}

	.ant-menu {
		li:first-child {
			margin-top: 15px;
		}
	}

	.popover {
		position: absolute;
		width: 200px;
		height: 100%;
		top: 0px;
	}

	.ant-menu-submenu-selected {
		.ant-menu-submenu-title {
			color: #878E9C;

			.iconfont {
				color: #878E9C;
			}
		}
	}

	.ant-menu-submenu-open {
		.ant-menu-submenu-title {
			color: #999;

			.iconfont {
				color: #999;
			}
		}
	}

	.ant-menu-submenu-open.ant-menu-submenu-selected {
		.ant-menu-submenu-title {
			color: #2B282E;

			.iconfont {
				color: #2B282E;
			}
		}
	}

	.ant-menu-submenu .ant-menu {
		// padding-left: 24px;
		font-size: 12px;
	}

	.ant-menu-submenu .ant-menu .ant-menu-item {
		// width: 50% !important;
		display: inline-block;
		margin: 0px 0 0px 0px !important;
		padding: 0 !important;
		font-size: 12px !important;
	}

	.ant-menu-submenu-selected .ant-menu-submenu-title {
		// background-color: #1790FF;
		background-image: url('../images/bg_submenu_selected_1.png');
		background-repeat: no-repeat;
	}

	.ant-menu-item-selected[target=yiji] {
		// background-color: #1790FF !important;
		background-color: #232323 !important;
		margin-left: 11px;
	}

}