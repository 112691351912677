.campain-setting,
.market-setting {
    width: 100%;
    min-height: 100%;
    background-color: #e9eef3;

    .content {
        background-color: #ffffff;
        border-radius: 4px;
        padding: 20px;

        .text-note,
        .text-remind {
            margin-right: 20px;
        }
    }

    .text-tag {
        margin-left: 20px;
    }
}

.campain-setting {
    .content {
        .homePageSetting {
            box-sizing: border-box;
            height: 76px;
            padding-left: 21px;
            padding-top: 15px;
            margin-bottom: 16px;
            background: #F5FAFD;
            border-radius: 4px;
            border: 1px solid #E0EEFF;

            .top {
                display: flex;
                align-items: center;

                .pageName {
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                }

                .tag {
                    width: 55px;
                    height: 20px;
                    line-height: 20px;
                    background: #1791FF;
                    border-radius: 2px;
                    text-align: center;
                    font-size: 12px;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }

            .bottom {
                margin-top: 10px;
                display: flex;
                align-items: center;

                span {
                    cursor: pointer;
                    margin-right: 16px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #1791FF;
                }
            }
        }
    }
}

.choosePage {
    overflow: hidden;
    cursor: pointer;
    width: 420px;
    height: 36px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #E6E6E6;
    text-align: center;
    font-size: 14px;
    color: #999999;
    line-height: 36px;
}

.campaignName {
    font-size: 12px;
    color: #999999;
}

.changeHomePageTime {
    .ant-calendar-picker-input {
        text-align: center;
    }
}


.market-setting {
    overflow: hidden;

    .sub-title {
        margin-left: 10px;
        line-height: 25px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(96, 98, 102, 1);
    }

    .content {
        padding: 20px;

        .tools {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
        }
    }

    padding:0px 10px 10px 10px;

    .ant-tabs-bar {
        margin-bottom: 0px;
    }

    .market-activity-block {
        cursor: pointer;
        margin-bottom: 16px;
        width: 360px;
        height: 106px;
        background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        border: 1px solid rgba(228, 231, 237, 1);
        display: flex;
        position: relative;
        margin-right: 16px;

        .iconfont {
            font-size: 35px;
            line-height: 64px;

        }

        .market-activity-switch {
            float: right;
            margin-right: 10px;

        }

        .ant-form-item {
            display: flex;
        }

        .new-tag {
            top: 0px;
            left: 0px;
            position: absolute;
            width: 30px;
            height: 16px;
            background: rgba(255, 93, 93, 1);
            color: #ffffff;
            font-size: 12px;
        }

        .new-tag:after {
            content: '';
            border-top: 8px solid transparent;
            border-right: 8px solid transparent;
            border-left: 8px solid rgba(255, 93, 93, 1);
            border-bottom: 8px solid transparent;
            position: absolute;
            left: 30px;
            top: 0;
        }

        .market-activity-block-icon {
            width: 80px;
            margin-right: 12px;
            padding: 16px 0px 0px 16px;
            text-align: center;
            line-height: 100%;

            .iconBac {
                width: 64px;
                height: 64px;
                border-radius: 50%;
            }
        }

        .market-activity-content {
            width: 280px;
            padding-top: 16px;

            .activity-title {
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: rgba(48, 49, 51, 1);
                line-height: 20px;
                margin-bottom: 4px;
            }

            .market-activity-desc {
                height: 18px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(96, 98, 102, 1);
                line-height: 17px;
                margin-top: 8px;
            }
        }
    }
}

.add-page {
    .choose-page {
        width: 400px;
        display: flex;
        justify-content: space-between;
    }

    .blank-page {
        width: 180px;
        height: 320px;
        border-radius: 4px;
        border: 1px solid #DCDFE2;
    }

    .makeup-page {
        border-radius: 4px;
        width: 180px;
        height: 320px;
        background-size: 100% 100%;
        background-image: url("http://udh.oss-cn-hangzhou.aliyuncs.com/5b064609-e75c-46ef-b275-23a14519ae52");

    }

    .input-name {
        width: 400px;
    }
}

.market-tab {
    overflow: hidden;
}