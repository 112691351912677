.coupon-post{
    width:850px!important;
    height:600px;
    .iconfont{
        color:#50AD4C;
        font-size:34px;
    }
    .coupon-post-content{
        display:flex;
        justify-content: space-between;
    }
    .anticon-question-circle {
        margin-left: 3px;

    }
    .ant-form{
        width:375px;
        margin:0 auto;

    }
    .coupon-post-title{
        font-size:14px;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(48,49,51,1);
        line-height:20px;
        margin-bottom:16px;
        text-align:center ;

    }
    #post{
        width:375px;
        height:626px;
        background-image: url('../images/bg_post.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        padding:56px 0px 0px 0px;
    }
    .post-content{
        width:375px;
        height:440px;
        text-align: center;
        .avator{
            width:81px;
            height:81px;
            background-size: cover;
            margin:0 auto;
            border-radius: 50%;
            img{
                width:51px;
                height:51px;
                border-radius:50%;
                margin-top:15px;
            }
        }
        .shop-name{
            text-align: center;
            height:33px;
            font-size:20px;
            font-weight:500;
            color:rgba(34,34,34,1);
            margin-top:15px;

        }
        .coupon-name {
            font-size:30px;
            font-weight:bold;
            color:rgba(44,44,44,1);
            line-height:29px;
            text-align: center;
            margin-bottom:10px;
            margin-top:15px;
            margin-right:4px;
        }
        .coupon-content {
            width: 130px;
            height: 96px;
            margin: 32px auto 40px;
            font-size:14px;
            text-align: center;
            .coupon-use-goods{
				margin-top: 10px;
                font-size: 14px;
                font-weight: 500;
                color: rgba(2,2,2,1);
                line-height: 20px;
            }
            .discount {
				width: 100%;
				height: 40px;
                font-size: 35px;
                font-weight:600;
                line-height: 40px;
            }
            .coupon-user-min-amount {
                font-size:8px;
                font-weight:500;
                color: rgba(2,2,2,1);
                line-height:17px;
                text-align: center;
            }
        }
        .coupon-date{
            margin-top:8px;
            margin-bottom: 15px;
            font-size:12px;
            font-family:Source Han Sans CN;
            font-weight:500;
            color:rgba(23,23,23,1);
            line-height:12px;
        }
    }
    footer{
        margin-top:0px;
        text-align: center;
        .ant-btn{
            margin-right:10px;
        }
    }
}
.coupon-manage{
    .action{
        height:32px;
        margin-top:10px;
        .text-note{
            margin-left:20px;
        }
        .text-note-right{
            float:right;
        }
    }
    .ant-form-item{
        display:inline-block;
        margin-left:16px;
    }
}
.post-desc{
    max-width:400px!important;
}

