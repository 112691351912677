.capcha {
  display: flex;
  height: 50px;
  justify-content: center;
  p {
    margin: 0px !important;
  }
  .capcha-pic {
    height: 35px;
    width: 89px;
    background-image: url("../images/bg_cap.jpeg");
    font-family: PingFangSC-Semibold;
    font-size: 14px;
    letter-spacing: 3px;
    user-select: none;
    cursor: pointer;
  }
  .text-note {
    font-size: 12px;
    line-height: 17px;
  }
}
