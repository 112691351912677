.page-manage {
  width: 100%;
  min-height: 100%;
  background-color: #e9eef3;
  padding: 0px 10px 10px 10px;
  .content{
    background-color: #fff;
    border-radius: 4px;
    padding:20px;
    margin-bottom: 30px;
    .action{
      margin:20px;
      .ant-btn{
        margin-right:10px;
      }
    }
  }
}