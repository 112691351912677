html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.flex {
  display: flex;
}
