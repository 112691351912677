.group-detail{
  width:100%;
  min-height:100%;
  background-color: #e9eef3;
  .group-content{
    background-color: #ffffff;
    padding:20px;
    .group-title{
      margin-bottom: 20px;
    }
  }
  .goods-info-img{
    margin-right:12px;
    img{
      border-radius: 10px;
    }
  }
  .goods-id{
    width:200px;
    border-radius: 100px;
  }
  .goods-name{
    width:420px;
    border-radius: 100px;
  }
  .anticon-caret-right svg{
    color:#0d87eb!important;
  }
}
.ant-card-head{
  height: 40px;
  background-color: #f5f7fa;
}
