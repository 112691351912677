.applet-manage {
	width: 100%;
	height: 100%;
	background-color: #e9eef3;
	padding: 0px 10px 10px 10px;

	.success {
		color: #0ea443;
	}

	.red {
		color: red;
	}

	.applet-manage-content {
		background-color: #ffffff;
		padding: 20px;

		.filter-content {
			background-color: #f5f7fa;
			border-radius: 10px;
			padding: 10px 20px;
			margin: 0px 0px 30px 0px;
			display: flex;
			align-items: center;

			.iconfont {
				color: #0ea443;
				font-size: 60px;
			}

			.text-content {
				font-size: 14px;
				color: #606266;
				margin-left: 8px;
				margin-right: 28px;

				.title {
					font-size: 20px;
					color: #303133;
					font-weight: bold;
				}
			}

			.btnWrap {
				flex-grow: 1;
				margin-right: 32px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-end;

				.btnItem {
					&:last-child {
						margin-top: 12px;
					}

					.btnLabel {
						margin-right: 12px;
					}
				}
			}
		}

		.table-header {
			margin-bottom: 8px;
			font-size: 20px;
			color: #303133;
			font-weight: bold;
		}
	}

	.seeHelp {
		margin-left: 6px;
		cursor: pointer;
		color: #1790FF;
	}
}

.modal-top {
	border-bottom: 1px solid #ebeef5;
	text-align: center;
	font-size: 12px;
	padding-bottom: 15px;
	color: #909399;

	.iconfont {
		color: #0ea443;
		font-size: 40px;
	}

	.title {
		font-size: 14px;
		color: #303133;
		font-weight: bold;
		margin-bottom: 4px;
	}
}

.form-content {
	height: 500px;
	overflow: auto;

	.divideWrap {
		margin-bottom: 12px;

		.divideTitle {
			display: flex;
			align-items: center;

			.bar {
				margin-right: 8px;
				width: 4px;
				height: 18px;
				background-color: #1790FF;
				border-radius: 3px;
			}
		}
	}

	.white-normal {
		.ant-form-item-label {
			white-space: normal !important;
			padding-top: 10px;
			line-height: 20px;
			padding-right: 10px;
		}

		&.pr40 {
			.ant-form-item-label {
				padding-right: 40px;
			}
		}

		.white-normal-radio.ant-radio-wrapper {
			padding-bottom: 5px;
			white-space: normal;
			display: flex;

			.ant-radio {
				padding-top: 5px;
				height: 16px;
				width: 16px;
			}
		}

		.text {
			font-size: 12px;
			color: #9a9a9a;
			line-height: inherit;
			height: 20px;
			margin-bottom: 8px;
			margin-top: -8px;

			.note {
				color: #576b95;
			}
		}
	}
}

.radio-content {
	text-align: center;
	padding: 24px 0;

	.ant-radio-wrapper:first-child {
		margin-right: 30px;
	}
}

.btn-content {
	margin-top: 10px;
	text-align: center;
}

.modal2 {
	.modal-top {
		text-align: left;
		margin-bottom: 20px;

		.title {
			font-size: 16px;
		}

		.red {
			color: #ff313f;
		}
	}

	.flex {
		display: flex;

		.text-note {
			font-size: 12px;
			color: #909399;
			line-height: 20px;
			width: 200px;
			text-align: justify;
			margin-left: 8px;
		}
	}
}

.mr10 {
	margin-right: 10px;
}

.ant-btn {
	font-size: 14px;
}

.ant-tooltip-inner {
	background-color: #ffffff !important;
	color: #000000 !important;
}

.anticon-question-circle {
	margin-left: 10px;
	cursor: pointer;
}