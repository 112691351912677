@charset "UTF-8";
.create-update-pop {
  margin-bottom: 150px; }

.pop-up-promotion, .create-update-pop {
  width: 100%;
  min-height: 100%;
  padding: 0px 10px 10px 10px;
  background-color: #e9eef3;
  overflow: hidden; }
  .pop-up-promotion .content, .create-update-pop .content {
    background-color: #ffffff;
    border-radius: 4px;
    padding: 20px; }
    .pop-up-promotion .content .coupon-title, .create-update-pop .content .coupon-title {
      width: 70px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Semibold,PingFang SC;
      font-weight: 600;
      color: #303133;
      line-height: 20px; }
    .pop-up-promotion .content .filter-content, .create-update-pop .content .filter-content {
      margin-bottom: 12px; }
    .pop-up-promotion .content .action, .create-update-pop .content .action {
      display: flex; }
    .pop-up-promotion .content .create-pop-up-promotion, .create-update-pop .content .create-pop-up-promotion {
      width: 214px;
      height: 103px;
      background: url("http://udh.oss-cn-hangzhou.aliyuncs.com/5983f499-4b65-4c31-9f5b-7919c07e2455");
      background-size: cover;
      cursor: pointer; }
    .pop-up-promotion .content .create-pop-up-ad-promotion, .create-update-pop .content .create-pop-up-ad-promotion {
      width: 214px;
      height: 103px;
      background: url("http://udh.oss-cn-hangzhou.aliyuncs.com/0600a7b9-c7c2-4dd0-851d-a3a76efbc7e5");
      background-size: cover;
      cursor: pointer; }
    .pop-up-promotion .content .text-note, .create-update-pop .content .text-note {
      margin-left: 10px; }
  .pop-up-promotion aside, .create-update-pop aside {
    background: #f5f7fa;
    border-radius: 4px;
    border: 2px dashed #e2e5ea;
    padding: 15px;
    margin-right: 48px;
    height: 520px; }
    .pop-up-promotion aside .title, .create-update-pop aside .title {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Semibold,PingFang SC;
      font-weight: 600;
      color: #303133;
      line-height: 20px;
      text-align: center;
      margin-top: -25px; }
  .pop-up-promotion .form-item, .create-update-pop .form-item {
    width: 360px; }
  .pop-up-promotion .ant-form-item, .create-update-pop .ant-form-item {
    display: flex; }
  .pop-up-promotion .pop-up-promotion-show, .create-update-pop .pop-up-promotion-show {
    margin: 0 auto;
    background: url("http://udh.oss-cn-hangzhou.aliyuncs.com/590162af-5632-4ac1-a2af-0bf6913f0cfa");
    width: 300px;
    height: 481px;
    border-radius: 32px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center; }
  .pop-up-promotion main, .create-update-pop main {
    width: 100%;
    display: flex;
    justify-content: space-between; }
  .pop-up-promotion .activity-footer, .create-update-pop .activity-footer {
    width: 100%;
    height: 60px;
    text-align: center;
    line-height: 60px; }
    .pop-up-promotion .activity-footer .ant-btn, .create-update-pop .activity-footer .ant-btn {
      margin-left: 10px; }
  .pop-up-promotion .coupon-list, .create-update-pop .coupon-list {
    margin-top: 8px;
    width: 574px;
    border-radius: 4px;
    border: 1px solid #e2e5ea;
    padding: 0px 20px 20px 20px; }
  .pop-up-promotion .photo-show, .create-update-pop .photo-show {
    color: #2589ff;
    cursor: pointer;
    cursor: pointer;
    width: 104px;
    text-align: center;
    height: 20px;
    position: absolute;
    top: 100px; }

.pop-up-coupon-less {
  background: url("http://udh.oss-cn-hangzhou.aliyuncs.com/b3c852d7-fab6-49b6-bf1a-72e177701995") no-repeat; }
  .pop-up-coupon-less .coupon {
    margin: 0 auto;
    width: 160px; }
    .pop-up-coupon-less .coupon .coupon-btn {
      width: 88px;
      height: 17px;
      background: linear-gradient(360deg, #ff5151 0%, #ff8c41 100%);
      box-shadow: 0px 2px 4px 0px rgba(255, 86, 97, 0.65);
      border-radius: 12px;
      font-size: 12px;
      font-family: PingFangSC-Semibold,PingFang SC;
      font-weight: 600;
      color: white;
      line-height: 17px;
      text-align: center;
      margin: 0 auto; }
    .pop-up-coupon-less .coupon .coupon-content {
      display: flex;
      width: 160px;
      height: 50px;
      align-items: center;
      justify-content: center; }
      .pop-up-coupon-less .coupon .coupon-content .discount_amount:before {
        content: "¥";
        width: 8px;
        font-size: 14px;
        font-family: PingFangSC-Semibold,PingFang SC;
        font-weight: 600;
        color: #f03d40;
        line-height: 20px;
        background: linear-gradient(360deg, red 0%, #ff8d4f 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; }
      .pop-up-coupon-less .coupon .coupon-content .discount_amount {
        font-size: 47px;
        font-family: PingFangSC-Semibold,PingFang SC;
        font-weight: 600;
        color: #f03d40;
        line-height: 66px;
        background: linear-gradient(360deg, red 0%, #ff8d4f 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; }
      .pop-up-coupon-less .coupon .coupon-content .coupon-type {
        width: 60px;
        font-size: 20px;
        font-family: PingFangSC-Semibold,PingFang SC;
        font-weight: 600;
        color: #f03d40;
        line-height: 28px;
        background: linear-gradient(360deg, red 0%, #ff8d4f 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; }
      .pop-up-coupon-less .coupon .coupon-content .coupon-money {
        width: 78px;
        font-size: 15px;
        font-family: PingFangSC-Semibold,PingFang SC;
        font-weight: 600;
        color: #f03d40;
        line-height: 21px;
        background: linear-gradient(360deg, red 0%, #ff8d4f 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; }

.pop-up-coupon-more {
  background: url("http://udh.oss-cn-hangzhou.aliyuncs.com/6fdd827c-fac6-4742-9adb-a70c5e74242c") no-repeat; }
  .pop-up-coupon-more .coupon-list {
    padding: 5px;
    background: #ffffff;
    border-radius: 5px;
    width: 150px;
    margin: 0 auto;
    transform: translateX(-5px);
    max-height: 260px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none; }
  .pop-up-coupon-more .coupon-list-tow {
    max-height: 180px; }
  .pop-up-coupon-more .coupon-list::-webkit-scrollbar {
    display: none; }
  .pop-up-coupon-more .coupon {
    width: 140px;
    background: url("http://udh.oss-cn-hangzhou.aliyuncs.com/27dd65fe-f8e1-46a1-ae94-8eb1d86cb44d");
    background-size: cover;
    margin-top: 10px;
    border-radius: 5px;
    padding-bottom: 5px; }
    .pop-up-coupon-more .coupon .coupon-btn {
      width: 88px;
      height: 17px;
      background: linear-gradient(360deg, #ffffff 0%, #F8F8F8 100%);
      box-shadow: 0px 2px 4px 0px rgba(255, 86, 97, 0.65);
      border-radius: 12px;
      font-size: 12px;
      font-family: PingFangSC-Semibold,PingFang SC;
      font-weight: 600;
      color: #c03636;
      line-height: 17px;
      text-align: center;
      margin: 0 auto; }
    .pop-up-coupon-more .coupon .coupon-content {
      display: flex;
      width: 150px;
      height: 50px;
      align-items: center;
      justify-content: center;
      color: #ffffff; }
      .pop-up-coupon-more .coupon .coupon-content .discount_amount:before {
        content: "¥";
        width: 8px;
        font-size: 8px;
        font-family: PingFangSC-Semibold,PingFang SC;
        font-weight: 600;
        line-height: 20px; }
      .pop-up-coupon-more .coupon .coupon-content .discount_amount {
        font-size: 28px;
        font-family: PingFangSC-Semibold,PingFang SC;
        font-weight: 600;
        line-height: 66px;
        width: 60px;
        display: inline-block;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      .pop-up-coupon-more .coupon .coupon-content .coupon-type {
        width: 60px;
        font-size: 14px;
        font-family: PingFangSC-Semibold,PingFang SC;
        font-weight: 600;
        line-height: 28px; }
      .pop-up-coupon-more .coupon .coupon-content .coupon-money {
        width: 78px;
        font-size: 9px;
        font-family: PingFangSC-Semibold,PingFang SC;
        font-weight: 600;
        line-height: 21px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block; }

.pop-up-coupon {
  width: 248px;
  min-height: 190px;
  background-size: 248px 170px;
  padding-top: 60px; }

.coupon-ad {
  width: 200px; }
