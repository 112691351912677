.style-setting{
  width:100%;
  height:100%;
  background: white;
  border-radius: 4px;
  background-color: #e9eef3;
  .ant-form-item{
    margin-right: 70px!important;
  }
  .style-content{
    padding:20px;
    background: white;
    border-radius: 4px;
  }
  .ant-radio-button-wrapper{
    height:30px!important;
    margin-right:10px;

  }
  .shopDataTitle{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #303133;
    font-family: PingFangSC-Semibold;
    .titleSign{
      width: 4px;
      height: 16px;
      background-color: #0d87eb;
      border-radius: 100px;
      display:inline-block;
      margin-right:10px;
    }
    .text-note{
      margin:10px;
    }
  }
  .title-content{
    background:rgba(255,255,255,1);
    border:1px solid rgba(220,223,230,1);
    opacity:1;
    border-radius:4px;
    padding:20px;

  }
  .color-choose{
    cursor: pointer;
    display:flex;
    width:128px;
    height:36px;
    background:rgba(255,255,255,1);
    border:1px solid rgba(220,223,230,1);
    opacity:1;
    border-radius:4px;
    justify-content: center;
    align-items: center;

  }
  .color{
    width:20px;
    height:20px;
    border:1px solid rgba(220,223,230,1);
    opacity:1;
    border-radius:2px;
  }
  .color-desc{
    width:60px;
    height:20px;
    font-size:14px;
    font-family:PingFang SC;
    font-weight:400;
    line-height:20px;
    color:rgba(85,85,85,1);
    opacity:1;
    margin-right:9px;
  }
  .color-picker-icon{
    width:12px;
    height:8px;
    padding:0px 10px;
    color:rgba(192,196,204,1);
    opacity:1;
  }
  .style-content{
    margin-bottom: 30px;
  }
  .footer{
    height:86px;
    background:rgba(255,255,255,1);
    box-shadow:0px -2px 8px rgba(0,0,0,0.07);
    opacity:1;
    border-radius:0px 0px 4px 4px;
    padding-top:27px;
  }

}
.photo-show {
  .ant-tooltip-inner {
    padding:0px;
  }
}
