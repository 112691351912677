.navbar{
	position:relative;
	height:40px;
	line-height:40px;
	font-family: PingFangSC-Semibold;
	font-size: 14px;
	.iconfont{
		color:#0d87eb;
	}
	.nav-father{
		font-family: PingFangSC-Semibold;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #2589ff;
		cursor: pointer;
	}
	.nav-no-father{
		color: #9798a0!important;
		font-size: 14px!important;
	}
	.nav-child {
		margin-left: 6px;
		font-size: 14px;
		font-weight: normal;
		letter-spacing: 0px;
		color: #303133;
	}
}
