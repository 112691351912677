@import '../common/sass/commom.sass';

.full-reduction {
    .ant-form-item {
        display: flex;
    }

    .ant-form-item-control-wrapper {
        flex: 1
    }

    .activity-condition-setting {
        width: 80%;
        padding: 20px;
        min-height: 204px;
        background: rgba(245, 247, 250, 1);
        border-radius: 10px;
        display: flex;

        .ant-divider {
            min-height: 204px;
        }

        .ant-form-item label {
            position: relative;
            margin-bottom: 10px;
        }

        .text-note {
            margin-left: 10px;
            text-decoration: underline;
        }

        .input-short {
            width: 100px;
        }

        .coupon-list-show {
            display: flex;
            flex-wrap: wrap;
        }

        .coupon {
            cursor: pointer;
            position: relative;
            margin-right: 12px;
            margin-bottom: 12px;
            height: 36px;
            background: rgba(255, 255, 255, 1);
            border-radius: 18px;
            border: 1px solid rgba(220, 223, 230, 1);

            .coupon-content {
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding: 0px 12px 0px 12px;
                line-height: 36px;
            }

            .icon-delete {
                position: absolute;
                top: -4px;
                right: -4px;
                color: #606266;
                display: none;
            }
        }

        .coupon:hover {
            .icon-delete {
                display: block;
            }
        }

        .shippingRadioGrooup {
            margin-left: 24px;

            .ant-radio-wrapper {
                margin-right: 0;
            }

            .anticon-question-circle {
                color: $mainBlue;
                margin-left: 0;
            }
        }

        .selectArea {
            cursor: pointer;
            margin-top: 6px;
            margin-left: 24px;
            color: $mainBlue;
        }

        .area {
            margin-left: 24px;
            margin-top: 6px;
        }

        .selectAddressTip {
            color: $errorRed;
            margin-left: 24px;
            margin-top: 6px;
        }
    }

    .integral-goods {
        display: flex;
        max-height: 454px;
        flex-wrap: wrap;

        .img-content {
            width: 102px;
            height: 102px;
            border-radius: 4px;
            cursor: pointer;
            border: 1px solid #d9d9d9;
            text-align: center;
            margin-right: 12px;
            position: relative;
            margin-bottom: 10px;

            .icon-del {
                color: #303133;
                position: absolute;
                top: -8px;
                right: -8px;
                z-index: 11111;
                font-size: 16px;
                display: none;

            }
        }

        .img-content:hover {
            .icon-del {
                display: inline-block;
            }

        }

        .integral-goods-show {
            display: flex;
            flex-wrap: wrap;
        }

        img {
            width: 90px;
            height: 90px;
            margin-top: 5px;
        }
    }

    .text-remind {
        margin-left: 10px;
        margin-right: 10px;
    }

    .ant-checkbox-wrapper {
        display: block;
        margin-bottom: 20px;
    }

    .ant-checkbox-wrapper+.ant-checkbox-wrapper {
        margin-left: 0px;
    }

    .coupon-goods-list {
        overflow-y: auto;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        overflow-x: hidden;
    }
}