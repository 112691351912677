.formTitle {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 6px 0;
    .titleSign {
        width: 4px;
        height: 16px;
        margin-right: 10px;
        border-radius: 999px;
        background-color: #0d87eb;
    }
    .title {
        font-size: 14px;
        color: #303133;
    }
}