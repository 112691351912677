.target-type-modal{
    width:1000px!important;
    .action{
        margin-bottom:20px;
        .text-note{
            margin-left:8px;
        }
    }
    .market-type{
        cursor: pointer;
        height:40px;
        line-height:40px;
        background:rgba(245,247,250,1);
        border:1px solid rgba(228,231,237,1);
        border-top-color:transparent;
        padding-left:20px;
        opacity:1;
        margin-bottom:5px;
        position:relative;
        top:-15px;
        .market-type-title{
            margin-right:10px;
            font-size:14px;
            font-family:PingFang SC;
            font-weight:bold;
            line-height:20px;
            color:rgba(96,98,102,1);
            opacity:1;

        }
    }
}
