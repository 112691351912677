.shop-nav{
    overflow: hidden;
    background: #ffffff;
    height:100%;
    display:flex;
    justify-content: space-around;
    padding-top:20px;
    .ant-form-item{
        display: flex!important;
    }
    .phone{
        width:375px;
        height:472px;

        background: #F4F4F4;
        .phone-title{
            width:375px;
            height:64px;
            background-image: url("http://udh.oss-cn-hangzhou.aliyuncs.com/503b756f-887e-4bb3-b5cd-f0ecc5f50dc9");
            background-size: cover;
            text-align: center;
            line-height:64px;
            font-size:18px;
            font-family:PingFangSC-Semibold,PingFang SC;
            font-weight:600;
            color:rgba(85,85,85,1);
            line-height:80px;
        }
        .phone-tab-bar{
            align-items: center;
            justify-content: space-around;
            text-align: center;
            margin-top:357px;
            display: flex;
            width:375px;
            height:49px;
            background:rgba(250,250,250,1);
            .iconfont{
                font-size:20px;
            }
            .title{
                font-size:12px;
                font-family:PingFangSC-Regular,PingFang SC;
                font-weight:400;
                line-height:17px;
            }
        }
    }
    .phone-content{
        width:415px;
        height:100%;
        position:relative;
        background: #f5f7fa;
        border-radius: 4px;
        border: 1px solid #e4e7ed;
        .phone-content-title{
            margin:12px 20px 9px 20px;
            font-size:16px;
            font-family:PingFangSC-Semibold,PingFang SC;
            font-weight:600;
            color:rgba(48,49,51,1);
            .text-danger{
                font-size:12px;
                font-family:PingFangSC-Regular,PingFang SC;
                font-weight:400;
                color:rgba(255,49,63,1);
                margin-left:8px;
            }
            .go-to{
                font-size:12px;
                font-family:PingFangSC-Regular,PingFang SC;
                font-weight:400;
                color:rgba(51,51,51,1);
                position:absolute;
                right:20px;
            }
        }
        .nav-color {
            width: 373px;
            margin: 0 auto;
            background: rgba(255, 255, 255, 1);
            border-radius: 4px;
            border: 1px solid rgba(220, 223, 230, 1);
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 10px 0px 0px 10px;
            .ant-form-item-label{
                width:100px!important;
            }
        }
        .color-choose{
            cursor: pointer;
            display:flex;
            width:128px;
            height:36px;
            background:rgba(255,255,255,1);
            border:1px solid rgba(220,223,230,1);
            opacity:1;
            border-radius:4px;
            justify-content: center;
            align-items: center;
        }
        .color-desc{
            width:60px;
            height:20px;
            font-size:14px;
            font-family:PingFang SC;
            font-weight:400;
            line-height:20px;
            color:rgba(85,85,85,1);
            opacity:1;
            margin-right:9px;
        }
        .color-picker-icon{
            width:12px;
            height:8px;
            padding:0px 10px;
            color:rgba(192,196,204,1);
            opacity:1;
        }
        .color{
            width:20px;
            height:20px;
            border:1px solid rgba(220,223,230,1);
            opacity:1;
            border-radius:2px;
        }
    }

}
