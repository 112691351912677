.group-goods-manage {
    width: 100%;
    height: 100%;
    background-color: #e9eef3;

    .content {
        background-color: #fff;
        border-radius: 4px;
        padding: 20px;
        margin-bottom: 30px;

        .action {
            margin: 20px;

            .ant-btn {
                margin-right: 10px;
            }
        }
    }

    .text-note {
        margin-right: 20px;
    }

    .pic-choose {
        margin-left: 10px;
    }

    .ant-upload-list-item {
        display: none;
    }

    .iconfont {
        cursor: pointer;
        color: #2589ff !important;
        margin-left: 10px;

        &.icon-biyan {
            color: #797A7E !important;
        }
    }

}

.cat-add-modal {
    .cat-input {
        width: 200px;
    }
}

.components-table-demo-nested {
    margin: 20px 0px !important;
}

.sortPop {
    width: 520px;

    .title {
        font-weight: bolder;
    }

    .ant-popover-inner-content {
        flex-direction: column;

        .ant-popover-message-title {
            padding-left: 0;

            .ant-form-item {
                margin-bottom: 0;
            }
        }
    }
}