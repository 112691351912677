.promote-monitor{
    width: 100%;
    min-height: 100%;
    padding:0px 10px 10px 10px;
    background-color: #e9eef3;
    overflow: hidden;
    .content{
        height:calc(100% - 40px);
        background-color: #ffffff;
        border-radius: 4px;
        padding:20px;
        position:relative;
        .filter-content{
            margin-top:20px;
        }
        .goods-id{
            width:200px;
        }
        .text-note{
            margin-left:10px;
        }
    }
    .remarks{
        resize:none;
        border-radius:5px;
        width:200px;
    }
    footer{
        width:calc(100% - 40px);
        text-align: center;
        position:absolute;
        bottom:20px;
    }
}
