body,#root {
	width: 100%;
	height:100%;
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
	"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.userDashboard {
	width:100%;
	height:100%;
	background-color: #e9eef3;
	padding:0px 20px;
	a {
		text-decoration: none;
	}
	.shopData:first-child{
		margin-bottom: 20px;
	}
	.dataContent{
		display: flex;
		.shop-info{
			flex:1;
			margin-right: 10px;
		}
	}
	.shopDataTitle{
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #303133;
		font-family: PingFangSC-Semibold;
		.titleSign{
			width: 4px;
			height: 16px;
			background-color: #0d87eb;
			border-radius: 100px;
			display:inline-block;
			margin-right:10px;
		}
	}
	aside {
		width:266px;
		.side-content{
			padding:20px 0px 20px 20px;
			background-color: #ffffff;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0px;
			color: #0e8fdd;
			margin-bottom: 20px;
			border-radius: 4px;
			.tip {
				color: #333333;
			}
			.noAppTip {
				padding-right: 20px;
				margin-top: 62px;
				margin-bottom: 90px;
				text-align: center;
				font-size: 16px;
				color: #333333;
				font-weight: 400;
				line-height: 22px;
			}
			.myQrcode {
				margin-left: 52px;
			}
			.newTime {
				margin-top: 7px;
				padding-right: 20px;
				text-align: center;
				font-size: 12px;
				color: #333333;
				line-height: 17px;
			}
			.status {
				margin-top: 2px;
				padding-right: 20px;
				text-align: center;
				color: #333333;
				line-height: 17px;
				font-size: 12px;
				.statusText {
					color: #E42317;
				}
				.seeDetail {
					cursor: pointer;
					margin-left: 7px;
					color: #1790FF;
				}
			}
			.iconfont{
				color: #0e8fdd;
				font-size: 14px;
			}

		}
	}
}
