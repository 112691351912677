.filter-content {
    background-color: #f5f7fa;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
}
.filter-action {
    margin-top: 20px;
}
.goods-list-page-detail{
    .text-note{
        margin-left:8px;
    }
    .action{
        margin-bottom:20px;
        .choose-num{
            margin-left:8px;
        }
    }
    .iconfont{
        color: #1890ff;
        margin-right:10px;
    }
    .goods-add-button-box {
        height: 86px;
        background-color: #ffffff;
        box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.07);
        text-align: center;
        line-height: 86px;
        margin-top: 10px;
        .ant-btn{
            margin-right:10px;
        }
    }
}
.goods-list-page{
    .iconfont{
        color: #1890ff;
        margin-left:10px;
        font-size:12px;
    }
    .action{
        margin-bottom:20px;
    }
    .text-note{
        margin-right:10px;
    }
}
