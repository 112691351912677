.self-shipment-modal{
  width:1000px!important;
  height:620px;
  .choose-goods{
    .goods-title{
      margin-bottom:10px;
    }

  }
  .shipment-info{
    .goods-title{
      margin-bottom:10px;
    }
    margin-top:20px;
    .address{
      margin-right:80px;
    }
  }
}