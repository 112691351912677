@charset "UTF-8";
.campaign-show .drag-sort,
.campaign-show-phone .drag-sort {
  display: block; }

.campaign-show .activity,
.campaign-show-phone .activity {
  cursor: pointer;
  border: 2px solid transparent;
  position: relative; }
  .campaign-show .activity .activity-action,
  .campaign-show-phone .activity .activity-action {
    display: none; }
  .campaign-show .activity .drag-btn,
  .campaign-show-phone .activity .drag-btn {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0; }

.campaign-show .activity:hover,
.campaign-show .activity.hover,
.campaign-show-phone .activity:hover,
.campaign-show-phone .activity.hover {
  border: 2px dashed #0d87eb; }

.campaign-show .current-activity,
.campaign-show-phone .current-activity {
  border: 2px solid #0d87eb !important; }
  .campaign-show .current-activity .activity-action,
  .campaign-show-phone .current-activity .activity-action {
    display: block; }

.campaign-show .activity-action,
.campaign-show-phone .activity-action {
  position: absolute;
  right: -47px;
  top: 0px; }

.campaign-show .btn-component,
.campaign-show-phone .btn-component {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #303133;
  height: 30px;
  line-height: 30px;
  margin-bottom: 8px;
  cursor: pointer; }
  .campaign-show .btn-component .iconfont,
  .campaign-show-phone .btn-component .iconfont {
    margin-right: 0; }

.campaign-show .iphone-content,
.campaign-show-phone .iphone-content {
  width: 320px;
  min-height: 560px;
  margin: 0 auto; }
  .campaign-show .iphone-content .iphone-title,
  .campaign-show-phone .iphone-content .iphone-title {
    background: url("http://udh.oss-cn-hangzhou.aliyuncs.com/9dc373f6-256c-4082-9108-d445b0f964e0");
    width: 100%;
    height: 50px;
    background-size: 100% 100%; }
  .campaign-show .iphone-content .phone-content,
  .campaign-show-phone .iphone-content .phone-content {
    min-height: 500px;
    background: url("http://udh.oss-cn-hangzhou.aliyuncs.com/7784e0ac-b6c3-48cc-95ac-54325322e583");
    background-size: 100% 100%;
    padding: 0px 20px; }
    .campaign-show .iphone-content .phone-content .drag-sort,
    .campaign-show-phone .iphone-content .phone-content .drag-sort {
      padding-bottom: 20px; }
  .campaign-show .iphone-content .iphone-nav,
  .campaign-show-phone .iphone-content .iphone-nav {
    background: url("http://udh.oss-cn-hangzhou.aliyuncs.com/a3d7ee4a-daa1-4f52-a879-c45c4f6853ce");
    width: 100%;
    height: 51px;
    background-size: 100% 100%; }

.campaign-show .ant-carousel .slick-slide,
.campaign-show-phone .ant-carousel .slick-slide {
  text-align: center;
  overflow: hidden; }

.campaign-show .goods-info,
.campaign-show-phone .goods-info {
  display: flex;
  padding: 0px 20px; }
  .campaign-show .goods-info .goods-name,
  .campaign-show-phone .goods-info .goods-name {
    flex: 1;
    font-size: 14px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: #555555;
    line-height: 29px; }
  .campaign-show .goods-info .goods-share-desc,
  .campaign-show-phone .goods-info .goods-share-desc {
    font-size: 6px; }

.campaign-show .goods-rank,
.campaign-show-phone .goods-rank {
  padding: 10px; }

.campaign-show .goods-show-detail,
.campaign-show-phone .goods-show-detail {
  margin-bottom: 32px; }
  .campaign-show .goods-show-detail .goods-show-detail-title,
  .campaign-show-phone .goods-show-detail .goods-show-detail-title {
    width: 60%;
    margin: 0 auto; }

.campaign-show .ant-carousel .slick-slide h3,
.campaign-show-phone .ant-carousel .slick-slide h3 {
  color: #fff; }

.campaign-show .iconfont,
.campaign-show-phone .iconfont {
  color: black; }

.campaign-show .original-price,
.campaign-show-phone .original-price {
  text-decoration: line-through; }

.campaign-show .goods-detail-footer,
.campaign-show-phone .goods-detail-footer {
  position: absolute;
  background: white;
  margin: -32px 20px 0px 20px; }
  .campaign-show .goods-detail-footer i,
  .campaign-show-phone .goods-detail-footer i {
    line-height: 32px;
    margin: 0px 8px; }
  .campaign-show .goods-detail-footer .ant-radio-group,
  .campaign-show-phone .goods-detail-footer .ant-radio-group {
    position: relative;
    right: -7px; }

.campaign-fixed {
  width: 100%;
  padding: 0px 10px; }
  .campaign-fixed .buyEveryDay {
    position: relative;
    width: 100%;
    height: 166px;
    background: url("http://udh.oss-cn-hangzhou.aliyuncs.com/61ef1758-705c-43b3-a16d-8735198898a7");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-bottom: 10px;
    padding: 11px 10px 0px 10px; }
    .campaign-fixed .buyEveryDay .title {
      color: #956928;
      font-size: 12px;
      font-weight: bold; }
    .campaign-fixed .buyEveryDay .ant-divider {
      background: #956928 !important; }
    .campaign-fixed .buyEveryDay .desc {
      color: #956928;
      font-size: 9px; }
    .campaign-fixed .buyEveryDay button {
      background: #956928;
      color: #ffffff;
      border-color: transparent;
      font-size: 9px !important;
      position: absolute;
      right: 10px; }
    .campaign-fixed .buyEveryDay .content {
      display: flex;
      justify-content: space-between;
      margin-top: 10px; }
      .campaign-fixed .buyEveryDay .content .goods {
        width: 60px;
        text-align: center;
        height: 60px;
        line-height: 70px; }
        .campaign-fixed .buyEveryDay .content .goods .pic {
          width: 60px;
          height: 60px;
          background: #e6e6e6;
          text-align: center;
          background-size: cover !important; }
        .campaign-fixed .buyEveryDay .content .goods .price {
          height: 20px;
          line-height: 20px;
          background: linear-gradient(137deg, #b98c3b 0%, #a27728 100%);
          border-radius: 11px;
          color: white;
          margin-top: 10px; }
  .campaign-fixed .memberDay {
    position: relative;
    width: 100%;
    background: url("http://udh.oss-cn-hangzhou.aliyuncs.com/a08d4b65-c979-486b-b0f3-5fde4c3e421f");
    height: 175px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-bottom: 10px;
    padding: 11px 10px 0px 10px; }
    .campaign-fixed .memberDay .title {
      color: #a91c1c;
      font-size: 12px;
      font-weight: bold; }
    .campaign-fixed .memberDay button {
      background: #9e4242;
      color: #ffffff;
      border-color: transparent;
      font-size: 9px !important;
      position: absolute;
      right: 10px; }
    .campaign-fixed .memberDay .content {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      height: 130px;
      padding-top: 10px;
      border-radius: 10px; }
      .campaign-fixed .memberDay .content .side-goods {
        width: 120px;
        display: flex;
        margin-bottom: 5px;
        background: white;
        border-radius: 4px; }
        .campaign-fixed .memberDay .content .side-goods .pic {
          width: 50px;
          height: 50px;
          background: #e6e6e6;
          text-align: center;
          line-height: 40px;
          background-size: cover !important; }
        .campaign-fixed .memberDay .content .side-goods .side-goods-group-detail {
          flex: 1;
          font-size: 9px;
          text-align: center; }
          .campaign-fixed .memberDay .content .side-goods .side-goods-group-detail .price {
            margin: 0 auto; }
        .campaign-fixed .memberDay .content .side-goods .price {
          height: 20px;
          line-height: 20px;
          background: linear-gradient(311deg, #811616 0%, #a91c1c 100%);
          border-radius: 11px;
          color: white;
          width: 40px;
          text-align: center;
          margin-top: 10px; }
      .campaign-fixed .memberDay .content .goods {
        width: 60px;
        text-align: center;
        height: 60px;
        line-height: 70px; }
        .campaign-fixed .memberDay .content .goods .pic {
          width: 60px;
          height: 60px;
          background: #e6e6e6;
          text-align: center;
          background-size: cover !important; }
        .campaign-fixed .memberDay .content .goods .goods-group {
          flex: 1; }
        .campaign-fixed .memberDay .content .goods .price {
          height: 20px;
          line-height: 20px;
          background: linear-gradient(311deg, #811616 0%, #a91c1c 100%);
          border-radius: 11px;
          color: white;
          margin-top: 10px; }

.timeLimitBuy {
  width: 100%;
  margin-bottom: 10px; }
  .timeLimitBuy.type1 {
    position: relative;
    height: 200px;
    background: url("http://udh.oss-cn-hangzhou.aliyuncs.com/63be88ca-774f-4f75-b6da-bd73bccab908");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 11px 10px 0px 10px; }
  .timeLimitBuy .pic {
    width: 60px;
    height: 60px;
    background: #e6e6e6;
    text-align: center;
    background-size: cover !important; }
  .timeLimitBuy .price {
    height: 20px;
    line-height: 20px;
    background: linear-gradient(312deg, #ff5151 0%, #ff8c41 100%);
    border-radius: 11px;
    color: white;
    margin-top: 10px;
    text-align: center; }
  .timeLimitBuy .title {
    color: white;
    font-size: 12px;
    font-weight: bold; }
  .timeLimitBuy .time-show {
    text-align: center; }
  .timeLimitBuy .time {
    background: black;
    color: #ffffff; }
  .timeLimitBuy .content {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 20px; }
  .timeLimitBuy button {
    background: #993e2d;
    color: #ffffff;
    border-color: transparent;
    font-size: 9px !important;
    position: absolute;
    right: 10px; }
  .timeLimitBuy .phone-content {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    background-color: white;
    height: 120px;
    padding-top: 20px;
    border-radius: 10px; }
    .timeLimitBuy .phone-content .goods {
      width: 60px !important;
      text-align: center;
      height: 60px;
      line-height: 70px; }
  .timeLimitBuy .time-limit-buy-goods-list .time-limit-buy-goods-item {
    margin-bottom: 10px;
    background-color: #fff;
    display: flex; }
    .timeLimitBuy .time-limit-buy-goods-list .time-limit-buy-goods-item .goods-image {
      min-width: 80px;
      width: 80px;
      height: 80px;
      position: relative; }
      .timeLimitBuy .time-limit-buy-goods-list .time-limit-buy-goods-item .goods-image .time-limit {
        position: absolute;
        height: 16px;
        line-height: 16px;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        font-size: 8px;
        color: #fff; }
    .timeLimitBuy .time-limit-buy-goods-list .time-limit-buy-goods-item .goods-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 4px 0;
      margin-left: 10px; }
      .timeLimitBuy .time-limit-buy-goods-list .time-limit-buy-goods-item .goods-info .goods-name {
        font-size: 12px;
        color: #2d2f30;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: initial; }
      .timeLimitBuy .time-limit-buy-goods-list .time-limit-buy-goods-item .goods-info .goods-price {
        color: #d32b1f;
        font-size: 8px; }
        .timeLimitBuy .time-limit-buy-goods-list .time-limit-buy-goods-item .goods-info .goods-price .current-price {
          font-size: 12px;
          margin-right: 4px; }
        .timeLimitBuy .time-limit-buy-goods-list .time-limit-buy-goods-item .goods-info .goods-price .original-price {
          text-decoration: line-through;
          color: #b7b7b7; }

.campaign-goods-slider {
  background-size: cover !important;
  overflow: hidden;
  margin: 0 auto; }
  .campaign-goods-slider .slide-content {
    width: 1000px; }
    .campaign-goods-slider .slide-content .campaign-goods-slider-goods {
      width: 100px;
      display: inline-block;
      height: 100px;
      text-align: center;
      margin-top: 10px; }
      .campaign-goods-slider .slide-content .campaign-goods-slider-goods .goods-pic {
        width: 60px;
        background: #fff;
        margin: 0px auto;
        height: 60px; }
      .campaign-goods-slider .slide-content .campaign-goods-slider-goods .goods-name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all; }
  .campaign-goods-slider .goodsListWrap .goodsListBacImg {
    width: 100%; }
  .campaign-goods-slider .goodsListWrap .goodsListContent {
    margin-top: -24px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    overflow-x: scroll; }
    .campaign-goods-slider .goodsListWrap .goodsListContent .goodsListItem {
      width: 110px;
      height: 150px;
      flex-shrink: 0;
      margin-left: 4px;
      background-color: #ffffff;
      border-radius: 8px; }
      .campaign-goods-slider .goodsListWrap .goodsListContent .goodsListItem .listContentDetail {
        box-sizing: border-box;
        padding: 3px 3px 5px 3px;
        height: 68px; }
        .campaign-goods-slider .goodsListWrap .goodsListContent .goodsListItem .listContentDetail .listContentTitle {
          height: 34px;
          line-height: 17px;
          display: -webkit-box;
          overflow: hidden;
          word-break: break-all;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          font-size: 12px;
          color: #333333;
          font-weight: 500; }
        .campaign-goods-slider .goodsListWrap .goodsListContent .goodsListItem .listContentDetail .listContentPriceWrap {
          margin-top: 6px; }
          .campaign-goods-slider .goodsListWrap .goodsListContent .goodsListItem .listContentDetail .listContentPriceWrap .nowPrice {
            font-size: 14px;
            color: #C81716; }
          .campaign-goods-slider .goodsListWrap .goodsListContent .goodsListItem .listContentDetail .listContentPriceWrap .originPrice {
            text-decoration: line-through;
            margin-left: 3px;
            font-size: 12px;
            color: #C3C3C3; }

.campain-banner {
  width: 270px;
  margin: 0 auto;
  overflow: hidden; }
  .campain-banner .slick-track {
    display: flex;
    align-items: center;
    justify-content: center; }
  .campain-banner .banner-pic {
    max-width: 270px;
    margin: 0 auto; }
  .campain-banner .ant-carousel .slick-dots li button {
    width: 24px;
    opacity: 1;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #a4a7ae; }
  .campain-banner .ant-carousel .slick-dots li.slick-active button {
    background: #000; }
  .campain-banner .swiper-container {
    width: 100%; }
    .campain-banner .swiper-container .swiper-pagination .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background: #7e7e7e;
      border: 1px solid #EAEAEB;
      border-radius: 50%; }
      .campain-banner .swiper-container .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: #333333; }
    .campain-banner .swiper-container .swiper-wrapper {
      display: flex; }
      .campain-banner .swiper-container .swiper-wrapper .swiper-slide {
        margin: auto;
        width: 100%; }
        .campain-banner .swiper-container .swiper-wrapper .swiper-slide .banner-pic-swiper {
          display: block;
          width: 240px;
          margin: 0 auto; }
        .campain-banner .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-prev, .campain-banner .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-next {
          z-index: 1; }
        .campain-banner .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-prev {
          margin-left: 30px;
          margin-right: -30px; }
        .campain-banner .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-next {
          margin-left: -15px; }
        .campain-banner .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active {
          width: 240px !important;
          margin-left: 15px;
          z-index: 9; }

.campaign-show .phone-content {
  height: 500px;
  overflow-y: auto; }

.campaign-show-phone,
.component-edit {
  min-height: 560px;
  overflow-y: auto;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  overflow-x: hidden; }

.campain-coupon {
  background-color: white;
  width: 276px;
  padding: 5px;
  text-align: center;
  overflow: hidden; }
  .campain-coupon .coupon-money {
    width: 70px; }
    .campain-coupon .coupon-money .coupon-price,
    .campain-coupon .coupon-money .coupon-discount {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 28px; }
    .campain-coupon .coupon-money .coupon-price {
      font-size: 14px; }
  .campain-coupon .couon-info {
    width: 120px; }
    .campain-coupon .couon-info .coupon-name,
    .campain-coupon .couon-info .coupon-use,
    .campain-coupon .couon-info .coupon-date {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
  .campain-coupon .coupon-button {
    text-align: center;
    width: 86px;
    padding-top: 10px;
    padding-left: 20px; }
  .campain-coupon .show-coupon {
    height: 56px;
    background-size: 100% 100%;
    display: flex;
    font-size: 8px;
    color: white; }
  .campain-coupon .show-more-coupon {
    height: 56px;
    width: 125px;
    background-size: 100% 100%;
    display: flex;
    font-size: 8px;
    color: white; }
  .campain-coupon .coupon-list {
    display: flex;
    justify-content: space-around; }
  .campain-coupon .coupon-more-list {
    display: flex;
    width: 1000px; }
    .campain-coupon .coupon-more-list .show-more-coupon {
      margin-right: 5px; }

.component-edit {
  min-height: 560px;
  overflow-y: auto;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none; }

.campagin-icon-link-list {
  display: flex;
  justify-content: space-around;
  height: 68px;
  background-color: white;
  text-align: center;
  align-items: center;
  width: 276px; }
  .campagin-icon-link-list .icon-link-img {
    width: 28px;
    height: 28px; }
  .campagin-icon-link-list .campagin-icon-link-list-title {
    overflow: hidden;
    /*超出部分隐藏*/
    text-overflow: ellipsis;
    /* 超出部分显示省略号 */
    white-space: nowrap;
    /*规定段落中的文本不进行换行 */
    width: 50px; }

.big-icon-link-list {
  height: 120px; }
  .big-icon-link-list .icon-link-img {
    width: 80px;
    height: 80px; }

.medium-icon-link-list {
  height: 120px; }
  .medium-icon-link-list .icon-link-img {
    width: 60px;
    height: 60px; }

.campaign-live .campaign-live-box {
  margin-bottom: 10px;
  position: relative;
  text-align: center; }
  .campaign-live .campaign-live-box .live-desc {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    height: 50px;
    background: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    bottom: 0px;
    font-size: 12px;
    line-height: 17px;
    padding: 10px;
    text-align: left; }
  .campaign-live .campaign-live-box button {
    color: #ffffff; }
  .campaign-live .campaign-live-box img {
    width: 276px; }

.component-edit::-webkit-scrollbar,
.campaign-show-phone::-webkit-scrollbar {
  width: 0 !important; }

.demo-list {
  display: flex;
  padding-left: 5px; }
  .demo-list .goods {
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 6px 15px 0 0; }
    .demo-list .goods .pic {
      width: 80px;
      text-align: center;
      height: 80px;
      line-height: 80px;
      background-color: #e6e6e6; }

.campaign-advertisement {
  display: flex;
  width: 100%; }
  .campaign-advertisement .campaign-advertisement-box {
    width: 100%;
    display: flex; }
    .campaign-advertisement .campaign-advertisement-box .ad-image {
      height: 100%;
      width: 100%; }
  .campaign-advertisement.type4 {
    flex-wrap: wrap; }
    .campaign-advertisement.type4 .campaign-advertisement-box {
      width: 50%; }
      .campaign-advertisement.type4 .campaign-advertisement-box:first-child {
        width: 100%; }
