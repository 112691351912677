.icon-choose{
    width:827px !important;
    .iconfont{
        color:#999999;
        margin:0 auto;
        font-size:45px;
    }
    .icon-title{
        display: inline-block;
        width:51px;
        text-align: left;
    }
    .content{
        display: flex;
        flex-wrap: wrap;
    }

    .icon-list{
        display:flex;
        align-items: center;

    }
    .icon-content{
        margin:10px;
        text-align: center;
        width:64px;
        height:64px;
        background:rgba(255,255,255,1);
        border-radius:4px;
        border:1px solid rgba(220,223,230,1);
        position:relative;
        overflow: hidden;
        cursor: pointer;
        .pop{
            width:100%;
            height:18px;
            font-size:12px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:400;
            line-height:17px;
            background:rgba(0,0,0,.6);
            color: #ffffff;
            position:absolute;
            bottom:0px;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;

        }
    }
    .active{
        position:absolute;
        top:0px;
        right:0px;
        width: 0;
        height: 0;
        border-top: 26px solid #0D87EB;
        border-left: 26px solid transparent;
        .iconfont{
            font-size:12px;
            color:#fff;
            position: absolute;
            top: -26px;
            left: -13px;
        }
    }
    .icon-content-active{
        border: 1px solid #0D87EB;
    }
}
