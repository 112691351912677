.group-manage{
  width:100%;
  min-height:100%;
  background-color: #e9eef3;
  padding:0px 10px 10px 10px;
  .content{
    .ant-select{
      width:200px;
      border-radius: 100px!important;
    }
    .ant-btn {
      font-size: 14px;
    }
    .group-manage-content{
      background-color:#ffffff;
      padding:20px;
    }
    .filter-content{
      background-color: #f5f7fa;
      border-radius: 10px;
      padding:20px;
      margin:0px 0px 30px 0px;

    }
    .action{
      margin-bottom:10px;
      .choose-num{
        margin-left:12px;
      }
    }
    .pagenation{
      text-align: center;
    }
    .goods-info{
      position: relative;
      width:300px;
      display:flex;
      .goods-info-img{
        flex:none;
        margin-right:12px;
        img{
          border-radius: 10px;
        }
      }
      .goods-info-detail{
        flex:none;
        .goods-info-name{
          width: 168px;
          height: 20px;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #606266;
          line-height: 20px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
        }
      }
    }

  }
  .goods-id{
    width:200px;
    border-radius: 100px;
  }
  .goods-name{
    width:420px;
    border-radius: 100px;
  }
  .ant-select-selection{
    border-radius: 100px;
  }
  .ant-calendar-picker-input{
    width:400px!important;
  }
  .filter-action{
    margin-top:20px;
    .text-note{
      margin-left:10px;
    }
  }

}