.goods-add{
    .info-collapse{
        margin-left:10px;
    }
    .no-show{
        display:none
    }
  width:100%;
  min-height:100%;
  background-color: #e9eef3;
  padding:0px 10px 10px 10px;
  .goods-content{
    background-color: #ffffff;
    padding:20px;
    border-radius:4px;
    .goods-title{
      margin-bottom:21px;
    }
     .ant-input{
      width:400px;
      height:36px;
      border-radius: 100px;
    }
    textarea{
      height: 100px!important;
      background-color: #ffffff!important;
      border-radius: 10px!important;
      border: solid 1px #dcdfe6!important;
    }
    .ant-select{
      width: 200px;
      height: 36px;
    }
    .select-option{
      width:80px;
    }
  }
  .panel-title{
    width: 100%;
    font-family: PingFangSC-Semibold;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #303133;
    .panel-desc{
      float:right;
      margin-right:15px;
    }
  }
  .anticon-caret-right svg{
    color:#0d87eb!important;
  }
  .title-sign{
    width: 4px;
    height: 16px;
    background-color: #0d87eb;
    border-radius: 100px;
    display: inline-block;
    margin-right: 10px;
  }
  .ant-collapse .ant-collapse-item .ant-collapse-header .anticon {
    left: initial;
    right: 16px;
  }
  .goods-add-button-box{
    height: 86px;
    background-color: #ffffff;
    box-shadow: 0px -2px 8px 0px
    rgba(0, 0, 0, 0.07);
    text-align: center;
    line-height: 86px;
    margin-top:30px;
  }
  .goods-add-button-box{
   .ant-btn-round:first-child{
     margin-right:10px;
   }
  }
  .goods-add-input{
    width: 100px!important;
    height: 36px;
  }
  .sku-name{
    margin-right:10px;
  }
  .shipment-fee{
    width:200px!important;
    margin-left:10px;
    margin-right:10px;
  }
  .radio-block{
    display: block;
    height: 60px;
    line-height:30px;

  }
  .buy-limit{
    width:118px!important;
    height:36px!important;
  }

}
