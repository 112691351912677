p {
    margin: 0;
    padding: 0;
}

.gradeManage {
    width: 100%;
    padding: 0 10px;

    .content {
        padding: 20px;
        background-color: #ffffff;
        border-radius: 4px;

        .grade {
            padding-left: 12px;

            .openStatus {
                margin-left: 12px;
                font-weight: bolder;
            }

            .updateBtn {
                margin-top: 24px;
                margin-bottom: 12px;
                display: block;
                font-size: 16px;
            }

            .updateBtnTip {
                margin-bottom: 24px;
                font-weight: bolder;
            }

            .expendAll {
                margin-top: 24px;
                cursor: pointer;
                text-align: center;
                color: #2589ff;
            }

            .color {
                width: 64px;
                height: 32px;
                border-radius: 4px;

                &.bac {
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
            }
        }

        .task {
            padding-left: 12px;
            padding-bottom: 24px;

            .title {
                margin-top: 24px;
                display: flex;
                align-items: center;

                .bar {
                    width: 2px;
                    height: 14px;
                    background: #1790FF;
                    border-radius: 2px;
                }

                .text {
                    margin-left: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #333333;
                }
            }

            .taskWrap {
                .taskItem {
                    margin-top: 24px;

                    .detail {
                        display: flex;
                        align-items: center;
                        margin-top: 8px;
                        padding-left: 24px;

                        .couponTagWrap {
                            display: inline-block;
                            margin-left: 8px;
                        }
                    }

                    .detailInput {
                        margin: 0 8px;
                        width: 120px;
                    }
                }
            }
        }

        .btnWrap {
            margin-top: 48px;
            display: flex;
            justify-content: center;
        }
    }
}