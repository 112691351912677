.groupActivitys {
    width: 100%;
    padding: 0 10px;

    .content {
        padding: 20px;
        background-color: #ffffff;
        border-radius: 4px;

        .introduction {
            display: flex;
            align-items: center;
            background-color: #f5f7fa;
            padding: 10px 20px;
            border-radius: 4px;

            .iconWrap {
                width: 64px;
                height: 64px;
                border-radius: 50%;
                background-color: #2B97F0;
                line-height: 64px;
                text-align: center;

                .icon-pintuan {
                    font-size: 32px;
                }
            }

            .incRight {
                margin-left: 8px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                .rightTop {
                    .shareCoupon {
                        color: #333333;
                        font-size: 20px;
                        font-weight: bolder;
                    }
                }
            }
        }

        .groups {
            display: flex;
            flex-wrap: wrap;
            margin-top: 48px;
            padding: 0 10px;

            .groupItem {
                overflow: hidden;
                width: 270px;
                height: 140px;
                margin-right: 34px;
                border-radius: 8px;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                text-align: center;
                cursor: pointer;

                .title {
                    margin-top: 26px;
                    font-size: 18px;
                    font-weight: 500;
                    color: #ffffff;
                }

                .desc {
                    margin-top: 6px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #ffffff;
                }

                .btn {
                    margin: 14px auto 0;
                    width: 82px;
                    height: 24px;
                    background-color: #FFCF86;
                    border-radius: 12px;
                    color: #836448;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }

        .tabsWrap {
            margin-top: 24px;
        }
    }
}

.tooltip-link-qrcode {
    .ant-form-item {
        margin-bottom: 0px !important;
    }
}