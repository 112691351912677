.topBar {
    height: 60px;
    // background-color: #F4F6FE;
    background-color: #232323;
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    padding-right: 60px;

    .dashboard-logo {
        height: 30px;
        margin-left: 12px;
    }

    .topbar-item {
        color: #ffffff;
        display: inline-block;
        font-size: 14px;
        height: 36px;
        line-height: 36px;
        cursor: pointer;
    }

    .topbar-login-item{
        display: flex;
        align-items: center;
        margin-left: 15px;

        .topbar-logo {
            font-size: 30px;
            font-weight: bold;
            font-style: italic;
            font-family: monospace;
        }

        .topbar-name {
            font-size: 16px;
            font-weight: 600;
            margin-left: 5px;
            margin-top: 5px;
        }
    }

    .topbar-dianpu-item {
        // width: 260px;
        // height: 36px;
        // border-radius: 100px;
        // background-color: #0964ae;
        // line-height: 36px;
        margin-right: 50px;
    }

    .iconfont {
        color: #ffffff;
        font-size: 16px;
    }

    .label-icon-dianpu {
        width: 28px;
        height: 28px;
        border-radius: 14px;
        background-color: #0d87eb;
        display: inline-block;
        text-align: center;
        margin: 0 4px;
        line-height: 28px;
    }

    .ant-select-selection {
        border: none;
        background-color: transparent;
        color: #ffffff;

        .ant-select-selection__rendered {
            line-height: 36px;
            height: 36px;
        }
    }

    footer {
        margin-top: 48px;
    }
}

li {
    text-align: left;
}

.add-shop {
    text-align: center;
    color: #0d87eb;
}

.nowShop {
    font-family: PingFangSC-Semibold;
    color: #0d87eb;
}

.xiaochengxu {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    color: #0ea443 !important;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    font-size: 40px !important;
    margin-bottom: 10px;
}

footer {
    margin-top: 38px;

    .ant-btn:last-child {
        margin-left: 10px;
    }
}

.xiaochengxu-desc {
    height: 17px;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #909399;
}

.iconfont {
    color: #ffffff;
    font-size: 16px;
}