.balance-manage {
	width: 100%;
	min-height: 100%;
	background-color: #e9eef3;
	padding: 0px 10px 10px 10px;

	.content {
		.balance-content {
			background-color: #ffffff;
			padding: 20px;
			border-radius: 4px;

			.iconfont {
				color: #0d87eb;
				cursor: pointer;
				margin-left: 14px;
			}

			.filter-content {
				padding: 20px;
				background-color: #f5f7fa;
				border-radius: 10px;
				margin-bottom: 30px;
			}
		}
	}

	.goods-id {
		width: 200px;
	}

	.ant-select-selection {
		border-radius: 100px;
		width: 200px !important;
	}

	.action {
		margin-top: 20px;
	}

	.text-note {
		margin-left: 10px;
	}
}

.flexPop {

	.ant-popover-inner-content {
		display: flex;
	}
}

.ant-popover-buttons {
	line-height: 40px;
}

.pagenation {
	margin-top: 20px;
	text-align: center;
}