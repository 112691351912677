.bonus-recharge {
    width: 100%;
    min-height: 100%;
    background-color: #e9eef3;
    padding: 0px 10px 10px 10px;
    .content {
        width: 100%;
        min-height: 914px;
        padding: 20px;
        background-color: #ffffff;
        border-radius: 4px;
        .text-content {
            margin-top:6px;
            font-size: 14px;
            color: #606266;
            margin-right: 28px;
            .title {
                font-size: 20px;
                color: #303133;
                font-weight: bold;
            }
        }
        .bonus-recharge-title {
            display: flex;
            background-color: #f5f7fa;
            border-radius: 10px;
            height: 100px;
            padding: 20px 0px;
            .iconfont {
                width: 64px;
                height: 64px;
                background-color: #f5a623;
                display: inline-block;
                font-size: 30px;
                border-radius: 50%;
                text-align: center;
                line-height: 60px;
                margin-right: 8px;
                margin-left: 20px;
            }
            .bonus-recharge-title-sign {
                font-family: PingFangSC-Semibold;
                font-size: 20px;
                font-weight: normal;
                font-stretch: normal;
                letter-spacing: 0px;
                color: #303133;
            }
            .text-note {
                text-decoration: underline;
            }
        }
        .recharge-content {
            padding: 44px 0px 44px 0px;
            text-align: center;
            .recharge-kind:last-child {
                margin-top: 26px;
            }
            .ant-btn-round {
                width: 100px;
                margin-right: 12px;
            }
            .ant-btn-round:last-child {
                margin-right: 0px;
            }
            .ant-input {
                width: 324px;
            }
            .kind-title {
                display: inline-block;
                width: 120px;
                text-align: right;
            }
        }
        .action {
            text-align: center;
        }
    }
}

.recharge-modal {
    width: 700px !important;
}

.example-modal {
    width: 1348px !important;
    ul {
        display: flex;
        li {
            flex: 1;
            text-align: center;
            div {
                width: 310px;
                background-image: url("http://udh.oss-cn-hangzhou.aliyuncs.com/644ca774-2bdf-4b0b-aa73-f9e02c3d6137");
                background-size: cover;
                padding: 70px 17px 65px 20px;
                height: 630px;

                img {
                    width: 270px;
                    height: 460px;
                }
            }

        }

    }
}

.wxscan {
    width: 502px;
    height: 300px;
    margin: 40px auto 56px auto;
    line-height: 24px;
    font-size: 14px;
    color: #333;
    display: flex;
    .rphone {
        width: 190px;
        height: 296px;
        background: url(../images/wxscan.png) no-repeat;
        background-position: 0px 0px;
        display: inline-block;
    }

    .scan {
        width: 286px;
        height: 100%;
        border-right: 1px dotted #e4e4e4;
        margin-right: 25px;

        .tip {
            width: 145px;
            height: 37px;
            margin: 0 0 15px 40px;
            background: url(../images/wxscan2.png) no-repeat;
        }
        .codeimg {
            border: 1px solid #e4e4e4;
            width: 220px;
            height: 220px;
            position: relative;
        }
        .tip2 {
            font-size: 12px;
            line-height: 24px;
            color: #666666;
            padding-top: 12px;

            #order-countdown {
                color: #d33a31;
            }
        }
    }

}

.integral-setting {
    padding: 0px 20px;
    .ant-form-item-label {
        width: 135px;
        text-align: left !important;
    }
    .ant-form-item-label {
        position: absolute;
        top: 0px;
    }
    .ant-form-item-control-wrapper {
        margin-left: 140px;
    }
    .icon-filter-content {
        background-color: #f5f7fa;
        border-radius: 10px;
        padding: 10px 20px;
        margin: 0px 0px 30px 0px;
        display: flex;
        align-items: center;
        .iconfont {
            font-size: 30px;
        }
        .text-note {
            margin-left: 10px
        }
        .text-content {
            font-size: 14px;
            color: #606266;
            margin-left: 8px;
            margin-right: 28px;
            .title {
                font-size: 20px;
                color: #303133;
                font-weight: bold;
            }
        }
    }
    .activity-withdraw {
        width: 64px;
        height: 64px;
        background: #FD964A;
        border-radius: 50%;
        text-align: center;
        line-height: 64px;
    }
    .bonus-recharge-title {
        background-color: #f5f7fa;
        border-radius: 10px;
        height: 100px;
        padding: 20px 0px;
        .iconfont {
            width: 60px;
            height: 60px;
            background-color: #f5a623;
            display: inline-block;
            font-size: 30px;
            border-radius: 30px;
            text-align: center;
            line-height: 60px;
            margin-right: 8px;
            margin-left: 20px;
        }
        .bonus-recharge-title-sign {
            font-family: PingFangSC-Semibold;
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #303133;
        }
        .text-note {
            text-decoration: underline;
        }
    }
    .input-money {
        width: 160px;
        margin-right: 20px;
    }
    .frozen-money {
        margin-right: 20px;
        width: 160px;
        height: 32px;
        background: rgba(245, 247, 250, 1);
        border: 1px solid rgba(220, 223, 230, 1);
        opacity: 1;
        border-radius: 18px;
        line-height: 32px;
        margin-top: 15px;
        .anticon-close {
            width: 14px;
            height: 14px;
            background: rgba(255, 0, 31, 1);
            opacity: 1;
            border-radius: 50%;
            color: #fff;
            font-size: 8px;
            line-height: 14px;
            float: right;
            margin-top: 9px;
            svg {
                margim-top: 1px;
            }
        }
    }
}
