.videoActivity {
    width: 373px;
    margin: 0 auto;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    background-color: #ffffff;

    .video-title {
        height: 40px;
        padding-left: 12px;
        line-height: 40px;
        border-bottom: 1px solid #DCDFE6;
        border-radius: 4px 4px 0 0;
        background-color: #E9EEF3;
        font-size: 14px;
        font-weight: 500;
        color: #2D2F30;
    }

    .video-content {
        padding: 15px;
        border-radius: 0 0 4px 4px;
        background-color: #ffffff;

        .uploadBtn {
            width: 90px;
            height: 90px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: #FAFAFA;
            border-radius: 3px;
            border: 1px dashed #D9D9D9;
            cursor: pointer;

            &.bacPic {
                background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(http://udh.oss-cn-hangzhou.aliyuncs.com/344da325-b726-4559-9751-80ee64d093ca%E5%93%88%E5%93%88%E5%93%88.jpg);
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }

            .iconPlus {
                font-size: 24px;
                color: #333333;
            }

            .iconEdit {
                color: #333333;
                font-size: 24px;
            }

            .addVideoText {
                margin-top: 6px;
                color: #666666;
                font-size: 12px;
            }
        }
    }

    .videoSubmit {
        display: block;
        margin: 20px auto;
        width: 317px;
        height: 41px;
    }
}