.goods-show{
  .iphone-content{
    width:320px;
    height:560px;
    margin:0px auto;
    padding:78px 0px 30px 0px;
    background: url("http://udh.oss-cn-hangzhou.aliyuncs.com/69e7d555-9914-41ec-883a-b85f2cd7aa7e");
  }
  .ant-carousel .slick-slide {
    text-align: center;
    height: 280px;
    line-height: 160px;
    overflow: hidden;
  }
  .content{
    position: relative;
    overflow: auto;
    padding: 0px 20px;
    height:405px;
  }
  .goods-info{
    display: flex;
    padding:0px 20px;
    margin-top:10px;

    .goods-name{
      font-size:14px;
      font-family:PingFangSC-Regular;
      font-weight:bold;
      color:rgba(85,85,85,1);
      width:200px;
    }
    .ant-divider-vertical{
      color:#333333;
      height:inherit;
      width:2px;
    }
    .goods-share{
      width:50px;
      text-align: center;
    }
    .goods-share-desc{
      font-size: 6px;
    }
  }
  .price{
    margin-left:20px;
  }
  .goods-rank{
    padding:10px;
  }
  .goods-show-detail{
    margin-bottom: 32px;
    .goods-show-detail-title{
      width:60%;
      margin:0 auto;
    }
  }
  .ant-carousel .slick-slide h3 {
    color: #fff;
  }
  .iconfont{
    color:black;
  }
  .original-price{
    margin-left:5px;
    color:#e8e8e8;
    text-decoration:line-through;
  }
  .goods-detail-footer{
    position:absolute;
    background: white;
    margin: -32px 20px 0px 20px;
    i{
      line-height:32px;
      margin :0px 8px;
    }
    .ant-radio-group{
      position:relative;
      right:-7px;
    }
  }
}