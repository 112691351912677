.print-modal{
  width:800px!important;
  padding:0px;
  background-color: #e9eef3;
  border-radius: 10px;
  .table{
    wdith:556px;

  }
  .ant-modal-header{
    border:0px;
    text-align: center;
    background-color: #e9eef3;
  }
  .ant-modal-body {
    background-color: #e9eef3;
    padding: 10px;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word;
  }
  .ant-modal-footer {
    background-color: #e9eef3;
    padding: 10px 16px;
    text-align: right;
    border-top: 0px;
    border-radius: 0 0 4px 4px;
  }
  .address-info{
    margin-bottom: 10px;
    padding:20px;
    width: 774px;
    height: 168px;
    background-color: #ffffff;
    border-radius: 10px;
    span{
      width: 70px;
      display:inline-block;
      text-align: right;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #303133;
      margin-right:6px;
    }
  }
}
