.data-statistics {
    width: 100%;
    min-height: 100%;
    padding: 0px 10px 10px 10px;
    background-color: #e9eef3;
    overflow: hidden;

    .content {
        width: 100%;
        background-color: #fff;
        border-radius: 4px;
        padding: 20px;
        overflow: hidden;
    }

    .shopDataTitle {
        margin: 48px 0 24px;
    }

    .filter-date {
        flex: 1;
        text-align: end;

        .form-input-long {
            margin-right: 10px;
        }
    }

    .integral-dashboard {
        display: flex;
        justify-content: center;
        text-align: center;
        justify-content: space-around;
        min-height: 140px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(228, 231, 237, 1);
        align-items: center;

        .integral-title {
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(96, 98, 102, 1);
            line-height: 22px;
        }

        .integral-data {
            height: 42px;
            font-size: 23px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: rgba(96, 98, 102, 1);
            line-height: 42px;
        }
    }
}