.app-order-item{
  text-align: center;
  padding: 20px;
  box-shadow: 0 0 20px 0 rgba(0,0,0,.07);
  .iconfont{
    color: #0d87eb;
    font-size:50px;
  }
  .app-name{
    margin: 10px;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    color: #333;
  }
  .app-desc{
    font-size: 12px;
    line-height: 19px;
    color: #666;
    margin:0px auto;
    width:210px;
    margin-bottom: 20px;
    height:60px;
  }
  .app-function{
    text-align: left;
    font-size:14px;
    margin:0px auto;
    width:210px;
    height:480px;
    margin-bottom: 20px;
  }
  .icon-cha{
    font-size:10px;
    color:red;
  }
  .icon-duihao{
    font-size:10px;
    color:#0ea443;
  }

  .surport{
    color: #666;
  }
  .no-surport{
    color: #ccc;
  }
}