.check-address{
  width:1000px!important;
  .check-item{
    height:60px;
  }
  .area-item{
    display:flex;
    height:50px;
    .region-item{
      width:100px;
    }
  }
  .ant-tooltip-inner .ant-checkbox-wrapper{
    display:block!important;
  }

}