.shipping-manage{
  width: 100%;
  min-height: 100%;
  padding: 0px 10px 10px 10px;
  background-color: #e9eef3;
  .content {
    width: 100%;
    min-height: 914px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 4px;
  }
  .text-note{
    margin-left:10px;
  }
  .action{
    margin-bottom:16px;
  }
  .template-action .text-note{
    float:right;
    margin-right:20px;
  }

}
.template-modal{
  width:1000px!important;
  .input-name{
    width:200px!important;
  }
  .shipment-template-info{
    display:flex;
  }
  .goods-add-input{
    width:100px;
  }
  .shipment-template-desc{
    flex:1

  }
  .shipment-template-action{
    width:150px;
    align-self: end;
    .text-note{
      display:inline-block;
      height:100%;
      line-height:100%;
      margin-right:10px;
    }
  }

}
