.goods-attr{
    background:rgba(255,255,255,1);
    border:1px solid rgba(220,223,230,1);
    opacity:1;
    border-radius:10px;
    margin-top:20px;
    position:relative;
    .attr-name-delete{
        position:absolute;
        top:10px;
        right:10px;

    }
  .iconfont{
    color:#606266;
    margin:0px 12px 0px 15px;

  }
    .attr-name{
        background:#F7F6FD;
        opacity:1;
        border-radius:10px 10px 0px 0px;
        padding:9px 20px 9px 20px;
    }
    .attr-value{
        padding:0px 20px 9px 20px;
    }
  .attr-value-title{
    margin-right:10px;
      margin-top:20px;
  }
  .delete-show{
    display: none;
    cursor: pointer;

  }
  .ant-select-search__field{
    width:100px!important;
  }
}

.value-show{
  position:relative;
  .value-delete{
    display: none;
    position:absolute;
    right:5px;
    top:-20px;
    width:20px;
    height:20px;
    cursor: pointer;

  }
}

.value-show:hover{
  position:relative;
  .value-delete{
    display: inline-block;
  }
}
.attr-value-title .ant-input{
  width:100px!important;
  margin-right:30px;
}
.goods-attr:hover{
  .delete-show{
    display: inline-block;
  }
}
.goods-attr-content{
  flex: none;

}
.ant-tooltip-placement-bottom .ant-tooltip-arrow, .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow, .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: 3px;
  border-width: 0 5px 5px;
  border-bottom-color: #ffffff;
}
.attr-tooltip{
    .ant-tooltip-content,.ant-tooltip-inner{
    height:128px;
    }
}
.ant-select-dropdown{
    z-index:9999;
}

