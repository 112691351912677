.scene-marketing {
  .success {
    color: #0ea443;
  }
  .red {
    color: red;
  }
  .ant-col-3 {
    margin-right: 10px;
  }
  .form-content {
    height: auto;
    .ant-radio-group.ant-radio-group-outline {
      width: 100%;
    }
  }
  .mb20 {
    margin-top: 8px;
    margin-bottom: 12px;
    display: inline-block;
  }
  .groupItem {
    .title,
    .desc {
      color: #806145 !important;
    }
  }
  .message-content {
    background: #f9f9f9;
    font-size: 12px;
    color: #333;
    padding: 13px 18px;
    .flex {
      display: flex;
      margin-bottom: 10px;
      line-height: 20px;
      .label {
        color: #aaaaaa;
      }
    }
  }
  .tips {
    color: #aaaaaa;
    font-size: 12px;
  }
  .selected-coupon {
    border-radius: 15px;
    border: 1px solid #d9d9d9;
    padding: 4px 10px;
    margin-right: 6px;
    position: relative;
    .img-close-icon {
      display: none;
      position: absolute;
      right: -5px;
      top: -5px;
    }
    &:hover {
      .img-close-icon {
        display: block;
      }
    }
  }
}
