.promote-manage{
    width: 100%;
    min-height: 100%;
    padding:0px 10px 10px 10px;
    background-color: #e9eef3;
    padding-bottom:100px;
    overflow: hidden;
    .content{
        background-color: #ffffff;
        border-radius: 4px;
        padding:20px;
        .filter-content{
            margin-top:20px;
            .text-note{
                margin-left:20px;
            }
        }
        .action{
            position:relative;
            .text-note{
                margin-right:10px;
            }

        }
    }
    .iconfont{
        color: #2589ff;
        margin-left:10px;
    }
}
.tooltip-link-qrcode{
    .ant-form-item{
        margin-bottom:0px!important;
    }
}
.tooltip-qrcode{
    width:180px;
    height:240px;
    background:rgba(255,255,255,1);
    border:1px solid rgba(220,223,230,1);
    opacity:1;
    border-radius:16px;
    margin: 0px auto;
    position:relative;
    text-align: center;
    padding-top:20px;
    img{
        width:150px;
        height:150px;
    }
    .no-qrcode{
        height:150px;
        text-align: center;
        padding-top:40px;
        color:#C0C4CC;
        margin-left:50%;
        margin-top:50%;
        transform: translate(-50%,-50%);
        .iconfont{
            color:#C0C4CC;
            font-size:40px;
        }
    }
    .btn-qrcode{
        margin:20px 0px;
        a{
            color:#ffffff!important;
        }
    }
}
