.banner-add {
	padding-bottom: 0px;

	.content {
		padding-bottom: 0px !important;
	}

	.text-danger {
		margin-right: 4px;
	}

	.iconfont {
		color: #0d87eb;
	}

	.demo-image-list {
		display: flex;
		width: 100%;
		margin-top: 6px;

		.demo-image-item {
			width: 100%;
			height: 100px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: rgba(250, 250, 250, 1);
			border: 1px solid rgba(217, 217, 217, 1);
		}

		&.type4 {
			flex-wrap: wrap;

			.demo-image-item {
				width: 50%;

				&:first-child {
					width: 100%;
				}
			}
		}
	}

	.ant-carousel {
		margin: 12px auto 0;
		width: 110px;

		.bannerSchematicDiagram {
			.slick-slide {
				min-height: unset;
				text-align: center;
				height: 150px;
				line-height: 150px;
				background: #FAFAFA;
				border: 1px solid #D9D9D9;
				overflow: hidden;
			}
		}
	}

	.swiper-container {
		width: 244px;
		margin: 12px auto;

		.swiper-pagination {
			.swiper-pagination-bullet {
				width: 6px;
				height: 6px;
				background: #7e7e7e;
				border: 1px solid #EAEAEB;
				border-radius: 50%;

				&.swiper-pagination-bullet-active {
					background-color: #333333;
				}
			}
		}

		.swiper-wrapper {
			display: flex;

			.swiper-slide {
				margin: auto;
				height: 124px;
				text-align: center;
				line-height: 124px;
				background: #FAFAFA;
				border: 1px solid #D9D9D9;

				&:nth-last-child() {
					margin-right: 0 !important;
				}

				&.swiper-slide-prev,
				&.swiper-slide-next {
					z-index: 1;
				}

				&.swiper-slide-active {
					height: 150px;
					line-height: 150px;
					z-index: 9;
				}
			}
		}
	}
}

.action-save {
	width: 373px;
	margin: 20px auto;

	.btn-save {
		width: 100% !important;
	}
}

.shopDataTitle {
	font-size: 14px;
	height: 20px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #303133;
	font-family: PingFangSC-Semibold;
	display: flex;
	align-items: center;
	margin-bottom: 8px;

	.titleSign {
		width: 4px;
		height: 16px;
		background-color: #0d87eb;
		border-radius: 100px;
		margin-right: 10px;
		display: inline-block;
	}
}

.market,
.market-tabpane,
.pop-up-promotion {
	.ant-tooltip {
		max-width: 600px !important;
	}

	.activity-detail {
		width: 200px;
		overflow: hidden;
		word-break: keep-all;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.flex-img {
		display: flex;
		padding-top: 10px;
	}

	.close-icon {
		display: block !important;
	}

	.ant-form-item {
		display: flex;
	}

	.ant-form-item-required {
		display: inline !important;
	}

	.filter-content {
		background-color: #f5f7fa;
		border-radius: 10px;
		padding: 10px 20px;
		margin: 0px 0px 30px 0px;
		display: flex;
		align-items: center;

		.icon-content {
			min-width: 60px;
			height: 60px;
			border-radius: 100%;
			text-align: center;
			line-height: 60px;

			.iconfont {
				font-size: 30px;
			}
		}

		.text-content {
			font-size: 14px;
			color: #606266;
			margin-left: 8px;
			margin-right: 28px;

			.title {
				font-size: 20px;
				color: #303133;
				font-weight: bold;
			}
		}
	}
}

.date-time-limit-buy {
	width: 373px;
	margin: 0 auto;
	background: rgba(255, 255, 255, 1);
	border-radius: 4px;
	border: 1px solid rgba(220, 223, 230, 1);
	margin-top: 10px;
	margin-bottom: 10px;
	padding: 0px 0px 10px 10px;

	.date-time-limit-buy-item {
		width: 246px !important;
	}
}

.banner-add,
.activity-add,
.market,
.market-tabpane {
	.content {
		background-color: #ffffff;
		border-radius: 4px;

		.ladderItem {
			position: relative;
			width: 100%;
			margin-top: 12px;
			border-radius: 12px;
			background-color: #ffffff;
			box-shadow: 1px 1px 3px 1px rgba(2, 2, 2, 0.2);
			border: 1px solid #d9d9d9;

			.ladder {
				padding: 10px;
				border-radius: 12px 12px 0 0;
				background-color: #f4f7fa;

				.ant-input-affix-wrapper {
					width: 160px;
				}
			}

			.reward {
				display: flex;
				align-items: center;
				padding: 10px;

				.groupRewardText {
					flex-shrink: 0;
				}

				.ant-checkbox-wrapper {
					margin-right: 24px;

					&.coupnCheckbox {
						margin-right: 0;
					}
				}

				.ant-checkbox-group {
					display: flex;
					align-items: center;
					flex-wrap: wrap;

					.ant-checkbox-wrapper {
						display: flex;
						align-items: center;

						span {
							&:nth-child(2) {
								display: flex;
								align-items: center;

								.text {
									flex-shrink: 0;
									margin-right: 8px;
								}

							}
						}
					}
				}

				.addCoupon {
					cursor: pointer;
					color: #1890ff;
					margin-right: 8px;
				}

				.couponTagWrap {
					width: 200px;
					display: flex;
					align-items: center;
					flex-wrap: wrap;
				}
			}

			.icon-guanbianniu {
				position: absolute;
				top: 0;
				right: 12px;
				color: #7d7d7d;
				cursor: pointer;

				&:hover {
					color: #a0a0a0;
				}
			}
		}

		.form-item {
			width: 420px;
			height: 36px;

			.ant-select-selection,
			.ant-select-selection {
				height: 36px;
			}
		}

		.action-next {
			text-align: center;
			margin-top: 20px;
		}

		.action {
			margin: 20px 0px;
		}

		.activity-footer {
			width: 100%;
			height: 86px;
			text-align: center;
			line-height: 86px;

			.ant-btn {
				margin-left: 10px;
			}
		}
	}

	.anticon-close-circle,
	.ant-calendar-picker-clear {
		display: none;
	}

	.pic-show {
		margin-left: 10px;
	}

	.input-info {
		width: 80px;
	}

	.form-input-date {
		width: 370px !important;
	}

	.ant-carousel .slick-slide {
		text-align: center;
		min-height: 600px;
	}

	.ant-carousel .slick-dots li button {
		width: 24px;
		opacity: 1;
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background: #a4a7ae;
	}

	.ant-carousel .slick-dots li.slick-active button {
		background: #000;
	}

	.action {
		margin-top: 20px;

		.ant-btn {
			margin-right: 20px;
		}
	}

	.color-choose {
		cursor: pointer;
		display: flex;
		width: 128px;
		height: 36px;
		background: rgba(255, 255, 255, 1);
		border: 1px solid rgba(220, 223, 230, 1);
		opacity: 1;
		border-radius: 4px;
		justify-content: center;
		align-items: center;

		.color {
			width: 20px;
			height: 20px;
			border: 1px solid rgba(220, 223, 230, 1);
			opacity: 1;
			border-radius: 2px;
		}
	}
}

.market {
	overflow: hidden;
	min-height: 100%;
	background-color: #e9eef3;
	padding: 0px 10px 10px 10px;
	width: 100%;

	.content {
		.enterGroupWrap {
			display: flex;
			justify-content: center;

			.enterGroupImg {
				margin-right: 255px;
				width: 335px;
				max-height: 610px;
			}
			.replyText {
				font-size: 12px;
				color: #666666;
				line-height: 24px;
			}
			.enterGroupSaveBtn {
				display: block;
				margin: 77px auto 0;
				width: 317px;
				height: 42px;
				background: #0D87EB;
				border-radius: 4px;
				font-size: 16px;
				font-weight: 500;
				color: #FFFFFF;;
			}
		}
	}
}

.market,
.market-tab,
.pop-up-promotion {
	.activity-coupon {
		background: rgba(240, 91, 91, 1);
		border-radius: 50%;
		width: 64px;
		height: 64px;
		text-align: center;
		line-height: 64px;

		.iconfont {
			font-size: 30px;
		}
	}

	.activity-lucky {
		background: #2b97f0;
		border-radius: 50%;
		width: 64px;
		height: 64px;
		text-align: center;
		line-height: 64px;
	}

	.activity-checkin {
		background: #fd964a;
		border-radius: 50%;
		width: 64px;
		height: 64px;
		text-align: center;
		line-height: 64px;
	}

	.activity-pointsStore {
		background: #fd964a;
		border-radius: 50%;
	}

	.activity-enterGroup {
		background: #9a5be7ff;
		border-radius: 50%;
	}

	.activity-freeOrder {
		background: #eb2f96;
		border-radius: 50%;
		width: 64px;
		height: 64px;
		text-align: center;
		line-height: 64px;
	}

	.form {
		overflow-x: auto;
		-ms-overflow-style: none;
		overflow-x: -moz-scrollbars-none;
	}

	.form::-webkit-scrollbar {
		display: none;
	}

	.money-recharge {
		text-decoration: underline #0d87eb;
		color: #0d87eb;
		margin-left: 5px;
	}
}

.date-time-limit-buy {
	.ant-form-item {
		margin-bottom: 0px !important;
	}

	.ant-form-item-label {
		width: 100px;
		text-align: left !important;
	}

	.ant-form-item-label {
		position: absolute;
		top: 0px;
	}

	.ant-form-item-control-wrapper {
		margin-left: 80px;
	}

	.ant-form-item-required {
		display: inline !important;
	}
}

.card-info {
	width: 373px;
	margin: 10px auto 0;

	.iconfont {
		color: #0d87eb;
	}

	.ant-card-head {
		font-size: 14px;
	}

	.target-type-show {
		display: inline-flex;
		border: 1px solid #dcdfe6;
		margin-right: 5px;
		padding: 0px 5px;
		cursor: pointer;

		.target-type-show-title {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			display: inline-block;
			width: 130px;
		}

		.anticon-close-circle {
			display: inline-block;
			visibility: hidden;
			margin-left: 5px;
			padding-top: 15px;
		}
	}

	.target-type-show:hover {
		.anticon-close-circle {
			visibility: visible;
		}
	}

	.icon-box {
		display: inline-block;
		width: 64px;
		height: 64px;
		background: rgba(255, 255, 255, 1);
		border-radius: 4px;
		border: 1px solid rgba(220, 223, 230, 1);
		text-align: center;
		line-height: 64px;
		margin-right: 10px;

		.iconfont {
			color: #999999;
			font-size: 30px;
		}
	}

	.btn-choose-targettype {
		width: 230px;
		height: 32px;
		background: rgba(255, 255, 255, 1) !important;
		color: rgba(96, 98, 102, 1) !important;
		border-radius: 4px;
		border: 1px solid rgba(220, 223, 230, 1) !important;
	}

	.text-remind {
		line-height: normal;
		font-size: 12px;
	}

	.ant-form-item {
		margin-bottom: 0px !important;
	}

	.ant-form-item-label {
		width: 100px;
		text-align: left !important;
	}

	.ant-form-item-label {
		position: absolute;
		top: 0px;
	}

	.ant-form-item-control-wrapper {
		margin-left: 100px;
	}

	.ant-form-item-required {
		display: inline !important;
	}

	.image-upload {
		display: flex !important;

		.text-remind {
			font-size: 12px;
		}
	}
}

.button-activity-goods {
	margin: 12px 0px 0px 21px;
	width: 373px;
	height: 50px;
	border-color: rgba(13, 135, 235, 1);
	color: rgba(13, 135, 235, 1);
}

.icon-disabed {
	color: #e4e7ed !important;
}

.market-card-info {
	background-color: #f5f7faff;
	border-radius: 4px;
	padding: 20px;
	margin-bottom: 20px;
	margin-top: 10px;

	.card-info-title {
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
	}
}

.market-tooltip {
	max-width: 700px;
}

.activity-add {
	.activity-target-desc {
		margin-left: 20px;
		margin-top: 10px;
	}

	.color-set {
		.ant-radio-button-wrapper {
			height: 30px !important;
			margin-right: 10px;
			margin-top: 5px;
		}
	}

	.goods-image-list {
		padding: 0 20px;
	}
}

.market-tab,
.pop-up-promotion {
	.form-short-item {
		width: 100px;
	}

	.activity-lucky {
		background: #2b97f0;
		border-radius: 50%;
		width: 60px;
		height: 60px;
		border-radius: 100%;
		text-align: center;
		line-height: 60px;
	}

	.order-content {
		padding: 20px;
	}

	.icon-filter-content {
		background-color: #f5f7fa;
		border-radius: 10px;
		padding: 10px 20px;
		margin: 0px 0px 30px 0px;
		display: flex;
		align-items: center;

		.iconfont {
			font-size: 30px;
		}

		.text-content {
			font-size: 14px;
			color: #606266;
			margin-left: 8px;
			margin-right: 28px;

			.title {
				font-size: 20px;
				color: #303133;
				font-weight: bold;
			}
		}
	}

	.free-order-rule {
		display: flex;
	}

	.help_user_cash_content {
		margin-left: 50px;
	}
}

.onsale {
	padding: 20px;

	.filter-content {
		display: block;
		padding-bottom: 20px;
		margin-top: 20px;

		.ant-form-item {
			display: inline-block;
			margin-right: 20px;
		}

		.input-short {
			width: 200px;
		}

		.text-note {
			margin-left: 10px;
		}
	}
}

.table-goods-info {
	display: flex;
	align-items: center;

	.flex-col {
		margin-left: 6px;
		display: flex;
		flex-direction: column;
		white-space: nowrap;
	}
}

.relative {
	position: relative;
	width: 48px;
	height: 48px;

	&.wd100 {
		width: 100px;
		height: 100px;
	}

	.unsale {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		color: #fff;
		font-size: 14px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}