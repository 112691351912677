.leader-interview {
  width: 100%;
  min-height: 100%;
  background-color: #e9eef3;
  .content {
    width: 100%;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 0px 20px;
    .leader-title {
      background-color: #f5f7fa;
      border-radius: 10px;
      padding: 30px;
      .title {
        font-family: PingFangSC-Semibold;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #303133;
      }
      .sub-title {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #606266;
      }
    }
    .post-content {
      display: flex;
      justify-content: center;
      .post-item {
        width: 320px;
        height: 600px;
        background: url("../images/post.jpeg") no-repeat center center;
        background-size: 100%;
        position: relative;
        img {
          width: 170px;
          height: 170px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 88px;
          border-radius: 4px;
        }
      }
    }
  }
  p {
    margin-top: 0;
    margin-bottom: 0px;
  }
  p:first-child {
    margin-bottom: 8px;
  }
}
