.codeManage{
  width:100%;
  height:100%;
  background-color: #e9eef3;
  padding:0px 10px 10px 10px;
  .dataContent{
    background-color: #ffffff;
    padding:20px;
    border-radius:4px;
    .search-content{
      background-color: #f5f7fa;
      border-radius: 10px;
      padding:20px;
       }
    .action{
      margin:30px 0px 10px 0px;
    }
  }
  .ant-input,.ant-select-selection{
    border-radius: 100px;
  }
  .pagenation{
    text-align: center;

  }
  .combined-shape {
    width: 130px;
    height: 124px;
    background-color: #ffffff!important;
    box-shadow: 0px 0px 6px 0px
    rgba(0, 0, 0, 0.14);
    border-radius: 4px;

  }
  .publish-record{
    float:right;
    margin-right:12px;
  }
  .official{
    margin-left:20px;
  }
  .text-note{
    margin-right:10px;
  }
}

.ant-tooltip-inner{
  background-color: #ffffff!important;
}
.ant-tooltip-arrow{
  border-top-color: #ffffff!important;
}
.commit_box{
  textarea{
    height: 100px;
    background-color: #ffffff;
    border-radius: 10px;
    border: solid 1px #dcdfe6;
  }
  .ant-form-item{
    margin-bottom:12px;
  }
}