.centerStyle {
    padding: 48px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #ffffff;

    .left {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 375px;
        margin-left: 140px;
        border: 1px solid #f4f4f4;
        border-radius: 24px;
        background-color: rgb(244, 244, 244);

        .negativeMargin {
            margin-top: -64px;
            z-index: 9;
        }

        .centerBac {
            width: 375px;
            height: 230px;
            position: relative;
            background: url(http://udh.oss-cn-hangzhou.aliyuncs.com/0317a7aa-d409-47b0-9c22-79b3d8bcffd8) no-repeat top center / 375px;
            border-radius: 0 0 30% 30%;

            .avatar {
                position: absolute;
                top: 17px;
                left: 25px;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: #979797;
                border: 1px solid #979797;
                text-align: center;
                line-height: 50px;
                font-size: 12px;
                color: #1f1f1f;
            }

            .nick {
                position: absolute;
                top: 21px;
                left: 84px;
                font-size: 12px;
                color: #1f1f1f;
            }
        }

        .vipServiceShow {
            margin: 4px auto 12px;
            width: 365px;
            border-radius: 4px;
            background-color: #ffffff;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .vipItem {
                width: 25%;
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .vipIcon {
                    width: 42px;
                    height: 42px;
                    margin-bottom: 6px;
                }

                .vipText {
                    text-align: center;
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                }
            }
        }

        .widthJus {
            width: 365px;
            margin: 0 auto;
        }
    }

    .right {
        width: 356px;
        min-height: 400px;
        margin-right: 138px;
        padding-bottom: 24px;
        background-color: #F5F7FA;

        .hightLight {
            padding: 24px 0 24px 20px;
            font-size: 18px;
            font-weight: bold;
            color: #2D2F30;
        }

        .vipService {
            display: flex;
            font-size: 18px;
            font-weight: bolder;
            padding: 0 24px;
            margin-top: 24px;
            justify-content: space-between;
            align-items: center;
        }

        .shili {
            margin-left: 12px;
            cursor: pointer;
            color: #0D87EB;
            font-size: 12px;
        }

        .submit {
            display: block;
            width: 320px;
            margin: 24px auto 0;
        }

        .card-info {
            width: auto;

            .btn-choose-targettype {
                width: 206px;
            }
        }

        .button-activity-goods {
            display: block;
            width: 320px;
            margin: 24px auto 0;
        }
    }
}