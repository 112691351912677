p {
    margin: 0;
    padding: 0;
}

.fissionCoupon {
    width: 100%;
    padding: 0 10px;

    .content {
        padding: 20px;
        background-color: #ffffff;
        border-radius: 4px;

        .introduction {
            display: flex;
            align-items: center;
            background-color: #f5f7fa;
            padding: 10px 20px;
            border-radius: 4px;

            .iconWrap {
                width: 64px;
                height: 64px;
                border-radius: 50%;
                background-color: #f05b5b;
                line-height: 64px;
                text-align: center;

                .icon-youhuijuan1 {
                    font-size: 32px;
                }
            }

            .incRight {
                margin-left: 8px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                .rightTop {
                    .shareCoupon {
                        color: #333333;
                        font-size: 20px;
                        font-weight: bolder;
                    }

                    .shili {
                        margin-left: 8px;
                        color: #027fff;
                        font-size: 14px;
                    }
                }

                .rightBot {
                    color: #606266;
                    font-size: 14px;
                }
            }
        }

        .newBtn {
            margin-top: 32px;
        }

        .formWrap {
            margin-top: 16px;
            margin-bottom: 48px;
            padding: 24px;
            background-color: #f5f7fa;
            border-radius: 4px;

            .selectStatus {
                width: 200px;
            }

            .btnWrap {
                margin-top: 16px;

                .clearSearch {
                    cursor: pointer;
                    margin-left: 12px;
                    color: #1890ff;
                }
            }
        }
    }
}