.activity-type-setting{
  width:100%;
  min-height:100%;
  background-color: #e9eef3;
  padding:0px 10px 10px 10px;
  .content{
    background-color: #ffffff;
    border-radius:4px;
    padding: 20px;
  }
  .shopDataTitle{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #303133;
    font-family: PingFangSC-Semibold;
    .titleSign{
      width: 4px;
      height: 16px;
      background-color: #0d87eb;
      border-radius: 100px;
      display:inline-block;
      margin-right:10px;
    }
  }
}
